import { Box, Paper, Typography } from "@mui/material";
import CardContainer from "../../../components/cards/CardContainer";
import { systemImgMap } from "../../../components/project/projectSystemMap";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { calculatePolygonArea } from "../../../components/map-grid-designer/helperFunctions/calcPolygonArea";

export const TitleCard = ({ project }) => {
  const { t } = useTranslation();
  const number = {
    nodes: 51,
    edges: 28,
    areas: 5,
  };
  const [area, setArea] = useState(0);
  useEffect(() => {
    if (project.projectAreas) {
      var newArea = 0;
      project.projectAreas.forEach((element) => {
        newArea += calculatePolygonArea(element.positions);
      });
      setArea(newArea);
    }
  }, [project]);

  return (
    <CardContainer>
      <img
        className="w-24 h-24 object-contain mx-auto"
        src={systemImgMap[project.system]}
      />
      <Typography variant="h3">{project.name}</Typography>
      <Typography variant="h4">
        {parseFloat(area / 1000000).toFixed(3)} km²
      </Typography>
    </CardContainer>
  );
};
