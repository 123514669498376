import { CssBaseline, ThemeProvider } from "@mui/material";
import Projects from "./scenes/projects";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ColorModeContext, useMode } from "./theme/theme";
import RequireAuth from "./security/RequireAuth";
import { Designer } from "./scenes/designer";
import Project from "./scenes/project";
import Scenario from "./scenes/scenario";

function App() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <RequireAuth debug={false}>
            <Routes>
              <Route path="*" element={<Projects />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/projects/:id" element={<Project />} />
              <Route path="/scenarios/:id" element={<Scenario />} />
              <Route path="/designer" element={<Designer />} />
            </Routes>
          </RequireAuth>
        </BrowserRouter>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
