import { Box } from "@mui/material";
import { MapButton } from "./mapUtility/MapButton";
import SettingsIcon from "@mui/icons-material/Settings";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { MapFilter } from "./mapUtility/MapFilter";
import { MapSearch } from "./mapUtility/MapSearch";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import { useMap } from "react-leaflet";
import { MapType } from "./mapUtility/MapType";

export const MapUtility = ({ setFilter, filter, center, preview }) => {
  const map = useMap();
  function recenterMap() {
    map.setView(center);
  }

  return (
    <Box
      className=" absolute top-2 left-2 flex flex-col gap-3"
      sx={{ zIndex: "9999" }}
    >
      {(!preview || preview.search) && <MapSearch />}

      {(!preview || preview.filter) && (
        <MapButton
          tooltip="Filter"
          Icon={FilterAltIcon}
          popover={<MapFilter setFilter={setFilter} filter={filter} />}
        />
      )}
      {(!preview || preview.recenter) && (
        <MapButton
          tooltip="Recenter Map"
          Icon={CenterFocusStrongIcon}
          onClick={recenterMap}
        />
      )}

      <MapType preview={preview} />
    </Box>
  );
};
