import Cookies from "universal-cookie";
const cookies = new Cookies();
export const numberToString = (
  number = 0,
  decimalPlaces = 2,
  separator = ".",
  thousandSeparator = " "
) => {
  if (
    number === "" ||
    parseFloat(number) == NaN ||
    number == null ||
    number == undefined
  )
    return `0${separator}`;
  if (!separator) separator = ".";
  var roundedNumber = parseFloat(number).toFixed(decimalPlaces);

  let parts = roundedNumber.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);

  return decimalPlaces ? parts.join(separator) : parts[0];
};

export const stringToNumber = (
  string = "0",
  decimalPlaces = 2,
  separator = ".",
  thousandSeparator = " "
) => {
  if (!string) return 0;
  if (!separator) separator = ".";
  const normalizedString = string
    .replace(thousandSeparator, "")
    .replace(separator, ".");
  return decimalPlaces
    ? normalizedString
    : parseFloat(normalizedString).toFixed(decimalPlaces);
};
