import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Polyline, useMap, useMapEvents } from "react-leaflet";
import useMapElementStyles from "../useMapElementStyles";
import ToolButton from "../tools/ToolButton";
import { findBiggestName } from "../helperFunctions/findBiggestName";

const EdgeTool = ({
  onAlert,
  mapElements,
  selectedElement,
  active,
  onClick,
  setMapElements,
  open,
  defaultProps,
}) => {
  const [edgeNodes, setEdgeNodes] = useState(null);
  const map = useMap(); // Access the Leaflet map instance

  const mapStyles = useMapElementStyles();

  useEffect(() => {
    if (active && selectedElement.type === "node")
      handleNodeClick(selectedElement);
  }, [selectedElement]);

  useEffect(() => {
    setEdgeNodes(null);
  }, [active]);

  function handleNodeClick(node) {
    if (!edgeNodes) {
      // set startnode
      setEdgeNodes({ start: node, end: node });
    } else if (edgeNodes && edgeNodes.start.id === node.id) {
      onAlert("An edge requires two different nodes.", "warning");
    } else if (!edgeExisting(edgeNodes.start.id, node.id)) {
      createNewEdge(edgeNodes.start, node);
    }
  }

  function edgeExisting(nodeId1, nodeId2) {
    // Check if there is already an edge between the nodes
    const hasConstraints = mapElements.some(
      (mapElement) =>
        (mapElement.type === "edge" &&
          mapElement.startNodeId === nodeId1 &&
          mapElement.endNodeId === nodeId2) ||
        (mapElement.startNodeId === nodeId2 && mapElement.endNodeId === nodeId1)
    );
    if (hasConstraints) {
      onAlert("An identical edge already exists.", "warning");
    }

    return hasConstraints;
  }

  function createNewEdge(node1, node2) {
    // create new edge
    const startNode = node1;
    const endNode = node2;
    const newEdge = {
      startNodeId: startNode.id,
      endNodeId: endNode.id,
      isSaved: false,
      id: uuidv4(),
      type: "edge",
      name: findBiggestName(mapElements) + 1,
      props: defaultProps,
    };

    setMapElements([...mapElements, newEdge]);
    // new Edge from last node
    setEdgeNodes({ start: endNode, end: endNode });
  }

  useMapEvents({
    contextmenu: (e) => {
      // Prevent the default context menu behavior
      e.originalEvent.preventDefault();
      setEdgeNodes(null);
    },
    mousemove: active ? handleMouseMove : () => {},
  });

  function handleMouseMove(e) {
    if (!edgeNodes || !edgeNodes.start) return;

    const { lat, lng } = e.latlng;

    // Convert start and end positions to container points
    const startContainerPoint = map.latLngToContainerPoint({
      lat: edgeNodes.start.position[0],
      lng: edgeNodes.start.position[1],
    });
    const endContainerPoint = map.latLngToContainerPoint({ lat, lng });

    // Calculate the distance in pixels
    const originalDistance = startContainerPoint.distanceTo(endContainerPoint);

    // Adjust the distance based on the desired pixel difference (5 pixels in this case)
    const adjustedDistance = originalDistance - 3;

    // Calculate the unit vector between start and end points
    const directionX =
      (endContainerPoint.x - startContainerPoint.x) / originalDistance;
    const directionY =
      (endContainerPoint.y - startContainerPoint.y) / originalDistance;

    // Calculate the new end position in container points
    const newEndContainerPoint = {
      x: startContainerPoint.x + directionX * adjustedDistance,
      y: startContainerPoint.y + directionY * adjustedDistance,
    };

    // Convert the new end position back to latLng
    const newEndLatLng = map.containerPointToLatLng(newEndContainerPoint);

    setEdgeNodes({
      ...edgeNodes,
      end: { ...edgeNodes.end, position: [newEndLatLng.lat, newEndLatLng.lng] },
    });
  }

  return (
    <>
      <ToolButton
        src={"/assets/svg/tools/edge.svg"}
        label="Edge"
        onClick={(e) => onClick(e, "edgeTool")}
        active={active}
        open={open}
      />

      {edgeNodes && (
        <Polyline
          key={edgeNodes}
          weight={mapStyles.edge.size.preview}
          positions={[edgeNodes.start.position, edgeNodes.end.position]}
          color={mapStyles.edge.colors.preview}
        />
      )}
    </>
  );
};

export default EdgeTool;
