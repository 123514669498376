import { Box } from "@mui/material";
import NodeTool from "./node/NodeTool";
import { FeatureGroup } from "react-leaflet";
import { useRef, useState } from "react";
import EdgeTool from "./edge/EdgeTool";
import DeleteTool from "./tools/DeleteTool";
import ToolButton from "./tools/ToolButton";
import ClearTool from "./tools/ClearTool";
import { AreaTool } from "./area/AreaTool";
import { ProjectAreaTool } from "./projectArea/ProjectAreaTool";
import { defaultNodeProps } from "./node/defaultNodeProps";
import { defaultAreaProps } from "./area/defaultAreaProps";
import { defaultEdgeProps } from "./edge/defaultEdgeProps";

export const MapTools = ({
  show,
  tools,
  onAlert,
  mapElements,
  setMapElements,
  currentMode,
  setCurrentMode,
  selectedElement,
  setProjectAreas,
  projectAreas,
  onProjectAreaCheck,
  setSelectedElement,
  customTools = [],
  maxProjectAreas,
}) => {
  const featureGroupRef = useRef(null);
  const [open, setOpen] = useState(true);

  function handleToolClick(e, tool) {
    if (tool === currentMode) {
      // Clear existing feature layer on the map
      featureGroupRef.current.clearLayers();
      setCurrentMode("edit");
    } else {
      setCurrentMode(tool);
    }
  }

  const toolsMap = {
    clear: (
      <ClearTool
        key="clear"
        onAlert={onAlert}
        selectedElement={selectedElement}
        onClick={(e) => handleToolClick(e, currentMode)}
        mapElements={mapElements}
        setMapElements={setMapElements}
        currentMode={currentMode}
        open={open}
        setProjectAreas={setProjectAreas}
        onlyProjectAreas={Boolean(
          (tools[0] === "projectAreas" || tools[1] === "projectAreas") &&
            tools.length === 2
        )}
      />
    ),
    delete: (
      <DeleteTool
        key="delete"
        onAlert={onAlert}
        selectedElement={selectedElement}
        onClick={handleToolClick}
        projectAreas={projectAreas}
        setProjectAreas={setProjectAreas}
        setSelectedElement={setSelectedElement}
        mapElements={mapElements}
        setMapElements={setMapElements}
        active={currentMode === "delete"}
        open={open}
      />
    ),
    projectArea: (
      <ProjectAreaTool
        key="projectArea"
        onAlert={onAlert}
        selectedElement={selectedElement}
        onClick={handleToolClick}
        projectAreas={projectAreas}
        setProjectAreas={setProjectAreas}
        active={currentMode === "projectAreaTool"}
        open={open}
        maxProjectAreas={maxProjectAreas}
      />
    ),
    center: (
      <ToolButton
        key="center"
        size={open ? 40 : 30}
        src={"/assets/svg/tools/tools.svg"}
        onClick={(e) => {
          handleToolClick(e, currentMode);
          setOpen(!open);
        }}
        open={true}
      />
    ),
    node: (
      <NodeTool
        key="node"
        onClick={handleToolClick}
        mapElements={mapElements}
        setMapElements={setMapElements}
        active={currentMode === "nodeTool"}
        featureGroupRef={featureGroupRef}
        onProjectAreaCheck={onProjectAreaCheck}
        onAlert={onAlert}
        open={open}
        defaultProps={defaultNodeProps}
      />
    ),
    edge: (
      <EdgeTool
        key="edge"
        onAlert={onAlert}
        selectedElement={selectedElement}
        onClick={handleToolClick}
        mapElements={mapElements}
        setMapElements={setMapElements}
        active={currentMode === "edgeTool"}
        open={open}
        defaultProps={defaultEdgeProps}
      />
    ),
    population: (
      <AreaTool
        key="population"
        onAlert={onAlert}
        selectedElement={selectedElement}
        onClick={handleToolClick}
        mapElements={mapElements}
        setMapElements={setMapElements}
        active={currentMode === "populationTool"}
        onProjectAreaCheck={onProjectAreaCheck}
        open={open}
        type={{
          img: "/assets/svg/tools/population.svg",
          label: "Population",
          tool: "populationTool",
        }}
        defaultProps={defaultAreaProps}
      />
    ),
    custom: (
      <>
        {customTools.map((tool, index) => (
          <ToolButton
            key={index}
            src={tool.src}
            label={tool.label}
            onClick={tool.onClick}
            open={open}
          />
        ))}
      </>
    ),
  };
  if (!show) return;
  return (
    <FeatureGroup ref={featureGroupRef}>
      <Box
        className="fixed bottom-0 left-0 w-full p-2 flex justify-center items-center gap-6 "
        sx={{
          zIndex: "9999",
          "::before": {
            content: "''",
            position: "absolute",
            width: "100%",
            height: "80px",
            bottom: "0",
            left: "0",
            transition: "opacity 0.6s ease",
            background:
              "linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 70%, rgba(0,0,0,0) 100%)",
            opacity: open ? "1" : "0",
          },
        }}
      >
        {tools.map((tool) => toolsMap[tool])}
      </Box>
    </FeatureGroup>
  );
};
