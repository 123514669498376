import { Box, Button, IconButton, Typography } from "@mui/material";
import useMapElementStyles from "../useMapElementStyles";

const ToolButton = ({
  src,
  onClick,
  active,
  open,
  label,
  size = 25,
  variant = "default",
}) => {
  const mapStyles = useMapElementStyles();
  const transform = "translateY(100px)";
  return (
    <Box
      className="flex flex-col items-center justify-center"
      sx={{
        transform: open
          ? active
            ? `translateY(-5px)`
            : `translateY(0px)`
          : transform,
        transition: "0.5s ease",
      }}
    >
      <IconButton
        onClick={onClick}
        sx={{
          bgcolor: active
            ? mapStyles.tools[variant].active
            : mapStyles.tools[variant].normal,
          ":hover": {
            bgcolor: mapStyles.tools[variant].hover,
          },
          padding: `${size / 2}px`,
        }}
        className=" transition-all aspect-square"
      >
        <img
          src={src}
          style={{
            width: `${size}px`,
            height: `${size}px`,
          }}
          className=" object-contain transition-all"
        />
      </IconButton>
      <Typography
        className=" overflow-visible whitespace-nowrap flex justify-center items-center"
        sx={{ color: mapStyles.tools[variant].text, width: `${size}px` }}
        textAlign="center"
      >
        {label}
      </Typography>
    </Box>
  );
};

export default ToolButton;
