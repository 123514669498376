import React, { useState, useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme/theme";

const CursorBox = ({ children }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  // Update position when mouse moves
  const handleMouseMove = (e) => {
    setPosition({ x: e.clientX, y: e.clientY });
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <Box
      className=" backdrop-blur-sm px-2 py-1 rounded-lg"
      sx={{
        position: "fixed",
        top: position.y + 10,
        left: position.x + 10,
        zIndex: 9999,
        bgcolor: colors.contrast[300] + "80",
      }}
    >
      {children}
    </Box>
  );
};

export default CursorBox;
