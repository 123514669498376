import { Box, IconButton, useTheme } from "@mui/material";
import MapGridDesigner from "../../../components/map-grid-designer/MapGridDesigner";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import { tokens } from "../../../theme/theme";
import { calculateCenter } from "../../../components/map-grid-designer/helperFunctions/calculateCenter";
import { calculateZoom } from "../../../components/map-grid-designer/helperFunctions/calculateZoom";

export const MapCard = ({ project, onOpenEditor }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box
      className="relative overflow-hidden col-span-1 lg:col-span-2 xl:col-span-3 2xl:col-span-4 rounded-lg"
      span={4}
      sx={{ maxHeight: "400px", height: "400px" }}
    >
      <IconButton
        onClick={onOpenEditor}
        className="top-2 right-2"
        sx={{ position: "absolute", zIndex: 9999 }}
      >
        <EditLocationIcon sx={{ color: colors.contrast[200] }} />
      </IconButton>
      {project.projectAreas && (
        <MapGridDesigner
          startCenter={calculateCenter(project.projectAreas)}
          startZoom={calculateZoom(project.projectAreas)}
          startProjectAreas={project.projectAreas}
          preview={{ projectAreas: true }}
          actions={[]}
        />
      )}
    </Box>
  );
};
