import { tokens } from "./theme";

//mui theme settings
export const themeSettings = (mode, customColorTheme) => {
  const colors = tokens(mode, customColorTheme);

  return {
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "999px",
          },
        },
      },
      MuiStep: {
        styleOverrides: {
          root: {
            svg: {
              fill: colors.contrast[100],
              path: {
                fill: colors.secondary[400],
              },
              circle: {
                fill: colors.contrast[300],
              },
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            "&.Mui-selected": { backgroundColor: colors.contrast[300] },
          },
        },
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            "& .MuiPaper-root": {
              backgroundColor: colors.contrast[300],
              color: colors.contrast[900],
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            borderRadius: "3px",
            "&.MuiInputBase-root": {
              borderRadius: "3px",
              overflow: "hidden",
              backgroundColor: colors.contrast[300],
              "::after": {
                borderBottom: "1px solid" + colors.contrast[500],
              },
              "::before": {
                borderBottom: "1px solid" + colors.contrast[300],
              },
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& label.Mui-focused": {
              color: colors.primary[800],
            },
            "& input::-webkit-inner-spin-button": {
              appearance: "none",
              margin: "0",
            },
            "& p.Mui-error": {
              height: "0",
              margin: "0",
            },
            "& fieldset": {
              borderWidth: "1px",
              borderColor: colors.primary[300],
              borderRadius: "10px",
            },
            "& .Mui-focused>fieldset": {
              borderWidth: "1px!important",
              borderColor: colors.primary[800] + "!important",
              borderRadius: "10px",
            },
          },
        },
      },
    },
    palette: {
      colorTheme: customColorTheme,
      mode: mode,
      ...{
        primary: {
          main: colors.contrast[100],
          dark: colors.contrast[800],
          contrastText: colors.contrast[900],
        },
        secondary: {
          main: colors.secondary[500],
          dark: colors.secondary[600],
          contrastText: colors.contrast[100],
        },
        neutral: {
          dark: colors.contrast[300],
          contrastText: colors.contrast[100],
        },
        background: {
          paper: colors.contrast[200],
        },
        table: {
          default: colors.primary[200],
        },
      },
    },
    typography: {
      fontFamily: "Montserrat",
      fontSize: 12,
      h1: {
        fontFamily: "Montserrat",
        fontSize: 40,
      },
      h2: {
        fontFamily: "Montserrat",
        fontSize: 32,
        fontWeight: "400",
      },
      h3: {
        fontFamily: "Montserrat",
        fontSize: 21,
        fontWeight: "500",
      },
      h4: {
        fontFamily: "Montserrat",
        fontSize: 16,
        textAlign: "justify",
        fontWeight: "400",
      },
      h5: {
        fontFamily: "Montserrat",
        fontSize: 12,
        color: colors.contrast[600],
        fontWeight: 400,
      },
      h6: {
        fontFamily: "Montserrat",
        fontSize: 12,
        textTransform: "uppercase",
        color: colors.contrast[600],
        fontWeight: 400,
      },
      p: {
        fontFamily: "Montserrat",
        fontSize: 14,
      },
      label: {
        fontFamily: "Montserrat",
        fontSize: 12,
        textTransform: "uppercase",
        color: colors.contrast[600],
        fontWeight: 600,
      },
    },
  };
};
