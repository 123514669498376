import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { I18nextProvider, initReactI18next } from "react-i18next";
import i18n from "i18next";

// Import translations
import deTranslations from "./language/de.json";

i18n.use(initReactI18next).init({
  resources: {
    de: { translation: deTranslations },
  },
  lng: "en", // Set the default language
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // React already does escaping
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
