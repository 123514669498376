import { useTheme } from "@mui/material";
import MapGridDesigner from "../../components/map-grid-designer/MapGridDesigner";
import { tokens } from "../../theme/theme";
import Mockdata from "./dummyMapElements.json";
import MockProjectAreas from "./dummyProjectArea.json";
import { DBRequest } from "../../api/dbRequest";

export const Designer = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  function handleSave(mapElements, projectAreas) {
    DBRequest({
      path: "projects",
      method: "post",
      onResponse: handleResponse,
      data: { mapElements, projectAreas },
    });
  }

  function handleResponse(res) {}

  function prepairMockdata() {
    var mockMapElements = [];
    Mockdata.forEach((element) => {
      if (element.type === "edge") {
        const weight = Math.floor(Math.random() * 6) + 3;
        mockMapElements.push({
          ...element,
          calc: [{ label: "diameter", value: weight }],
        });
      } else {
        mockMapElements.push(element);
      }
    });
    return mockMapElements;
  }

  const actions = [
    {
      label: "Upload DEM File",
      color: "primary",
      variant: "contained",
      onClick: (e) => console.log(e),
    },
    {
      label: "Generate Base Grid",
      color: "success",
      variant: "outlined",
      onClick: (e) => console.log(e),
    },
    {
      label: "Assign Elevation",
      color: "success",
      variant: "outlined",
      onClick: (e) => console.log(e),
    },
    {
      label: "Assign Dryweatherflow",
      color: "success",
      variant: "outlined",
      onClick: (e) => console.log(e),
    },
    {
      label: "Design",
      color: "success",
      variant: "contained",
      onClick: (e) => console.log(e),
    },
  ];

  return (
    <MapGridDesigner
      polygonColor={colors.redAccent[300]}
      selectColor={colors.secondary[500]}
      startElements={prepairMockdata()}
      startProjectAreas={MockProjectAreas}
      onSave={handleSave}
      actions={actions}
    />
  );
};
