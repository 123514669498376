import { createElement, useEffect, useRef, useState } from "react";
import { CircleMarker, Marker, Tooltip, useMap } from "react-leaflet";
import useMapElementStyles from "../useMapElementStyles";
import { updateMapElementById } from "../helperFunctions/updateMapElementById";
import { Icon } from "leaflet";
import { Box, Typography } from "@mui/material";
import { numberToString } from "../../numberConverter";
import { useTranslation } from "react-i18next";

const Node = ({
  element,
  currentMode,
  onSelect,
  selectedElement,
  mapElements,
  setMapElements,
  onProjectAreaCheck,
  isInSelection,
}) => {
  useEffect(() => {
    calcColor();
  }, [isInSelection, selectedElement, element]);
  const { t } = useTranslation();
  const mapStyles = useMapElementStyles();

  const [position, setPosition] = useState(element.position);
  const [key, setKey] = useState(0);
  const nodeRef = useRef(null);
  const map = useMap(); // Access the Leaflet map instance
  const [draggable, setDraggable] = useState(false);
  const [color, setColor] = useState(mapStyles.node.colors.default);

  const radius = draggable
    ? mapStyles.node.size.draggable
    : mapStyles.node.size[currentMode]
      ? mapStyles.node.size[currentMode]
      : mapStyles.node.size.default;

  const isSelected = selectedElement.id === element.id;

  function calcColor() {
    var newColor =
      mapStyles.node.colors[currentMode] || mapStyles.node.colors.default;

    if (isOutlet) newColor = mapStyles.node.colors.isOutlet;
    // not saved
    if (!element.isSaved) newColor = mapStyles.node.colors.notSaved;
    // is selected
    if (isSelected) newColor = mapStyles.node.colors.selected;
    // is in selection
    if (isInSelection) newColor = mapStyles.node.colors.inSelection;

    setColor(newColor);
    setKey(key + 1);
  }

  const pumpProp =
    (element.props &&
      element.props.find((prop) => prop.key === "lift_station")) ||
    null;
  const isPump = pumpProp ? pumpProp.value : false;
  // is outlet Candidate
  const outletProp =
    (element.props &&
      element.props.find((prop) => prop.key === "outlet_candidate")) ||
    null;
  const isOutlet = outletProp ? outletProp.value : false;

  const fillOpacity = draggable
    ? mapStyles.node.fillOpacity.draggable
    : mapStyles.node.fillOpacity[currentMode]
      ? mapStyles.node.fillOpacity[currentMode]
      : mapStyles.node.fillOpacity.default;

  useEffect(() => {
    setKey(key + 1);
    setDraggable(currentMode === "edit");
  }, [currentMode, element]);

  useEffect(() => {
    setPosition(element.position);
  }, [element]);

  function updateNodes() {
    if (!nodeRef.current) return;
    const position = nodeRef.current.getLatLng();
    const newElement = {
      ...element,
      isSaved: false,
      position: [position.lat, position.lng],
    };
    setMapElements(updateMapElementById(mapElements, newElement));
  }

  function handleMouseDown(e) {
    // Disable text selection on the map container or any specific element
    const mapContainer = map.getContainer();
    mapContainer.style.userSelect = "none";
    mapContainer.style.webkitUserSelect = "none"; // For Chrome, Safari, and Opera
    mapContainer.style.MozUserSelect = "none"; // For Firefox
    mapContainer.style.msUserSelect = "none";
    if (!draggable) return;
    map.dragging.disable();
    map.on("mousemove", function (e) {
      const { lat, lng } = e.latlng;
      const newPosition = [lat, lng];
      if (onProjectAreaCheck(newPosition)) {
        setPosition(newPosition);
        // live update of Edges on drag -> LAGGING
        //updateNodes();
      }
    });
  }

  function handleMouseUp() {
    // Enable text selection on the map container or the specific element after mouse up
    const mapContainer = map.getContainer();
    mapContainer.style.userSelect = "";
    mapContainer.style.webkitUserSelect = "";
    mapContainer.style.MozUserSelect = "";
    mapContainer.style.msUserSelect = "";
    if (!draggable) return;
    map.removeEventListener("mousemove");
    map.dragging.enable();
    updateNodes();
  }

  const handleMouseOver = () => {
    if (nodeRef.current && !isOutlet && !isPump) {
      nodeRef.current.setStyle({
        color: mapStyles.node.colors.hover || mapStyles.node.colors.default,
      }); // Change color on hover
    }
  };

  const handleMouseOut = () => {
    if (nodeRef.current && !isOutlet && !isPump) {
      nodeRef.current.setStyle({ color: color }); // Reset color on mouseout
    }
  };

  const eventHandlers = {
    mousedown: (e) => {
      handleMouseDown(e);
      onSelect(element);
    },
    mouseover: handleMouseOver,
    mouseout: handleMouseOut,
    mouseup: () => handleMouseUp(draggable),
  };

  // custom Icons
  const iconRadius = radius + 7;
  const outfallCandidate = new Icon({
    iconUrl: isSelected
      ? "/assets/svg/map/outfall-selected.svg"
      : "/assets/svg/map/outfall.svg",
    iconSize: [iconRadius * 2, iconRadius * 2], // size of the icon
    iconAnchor: [iconRadius, iconRadius], // point of the icon which will correspond to marker's location
    popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
  });

  const pump = new Icon({
    iconUrl: isSelected
      ? "/assets/svg/map/pump-selected.svg"
      : "/assets/svg/map/pump.svg",
    iconSize: [iconRadius * 2, iconRadius * 2], // size of the icon
    iconAnchor: [iconRadius, iconRadius], // point of the icon which will correspond to marker's location
    popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
  });

  // elevation
  const elevation = element.props.find((obj) => obj.key === "elevation");

  return (
    <>
      {createElement(isOutlet || isPump ? Marker : CircleMarker, {
        fillOpacity: fillOpacity,
        key: key,
        ref: nodeRef,
        center: position,
        eventHandlers: eventHandlers,
        radius: radius,
        color: color,
        position: position,
        icon: isOutlet ? outfallCandidate : pump,
        children: element.name && (
          <Tooltip
            direction="bottom"
            offset={[0, iconRadius]}
            opacity={1}
            permanent={false}
          >
            <Typography> {`${t("Name")}: ${element.name}`}</Typography>
            {elevation && (
              <Typography>{`${t("Elevation")}: ${numberToString(elevation.value)} ${elevation.unit}`}</Typography>
            )}
          </Tooltip>
        ),
      })}
    </>
  );
};

export default Node;
