import { Box, Button, Dialog, Tooltip, Typography } from "@mui/material";
import useMapElementStyles from "./useMapElementStyles";
import { useState } from "react";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import SaveIcon from "@mui/icons-material/Save";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme/theme";
import ExoAlert from "../exo/ExoAlert";
import { areMapElementsUnsaved } from "./helperFunctions/areMapElementsUnsaved";

export const MapActions = ({
  actions,
  onSave,
  onClose,
  mapElements,
  projectAreas,
  takeMeBack,
  forceSaveOnActions,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const mapStyles = useMapElementStyles();

  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState(null);
  function handleActionsClick() {
    if (forceSaveOnActions && areMapElementsUnsaved(mapElements)) {
      setAlert({
        submitLabel: t("Save"),
        message: t(
          "There are unsaved mapelements. You need to save them first."
        ),
        type: "warning",
      });
    } else {
      setOpen(true);
    }
  }

  return (
    <>
      {takeMeBack && (
        <Box
          className="bottom-4 left-8 absolute flex gap-3"
          sx={{ zIndex: "99999" }}
        >
          <NavLink to={takeMeBack.to}>
            <Button
              onClick={takeMeBack.onClick}
              variant="outlined"
              color="error"
              sx={{
                bgcolor: colors.contrast[100],
                ":hover": {
                  bgcolor: colors.contrast[100],
                },
              }}
              endIcon={takeMeBack.icon}
            >
              {takeMeBack.label || t("Back")}
            </Button>
          </NavLink>
        </Box>
      )}
      <Box
        className="bottom-4 right-8 absolute flex gap-3"
        sx={{ zIndex: "99999" }}
      >
        {onSave && (
          <Button
            onClick={onSave}
            variant="contained"
            color="success"
            sx={{
              bgcolor: mapStyles.actions.normal,
            }}
            endIcon={<SaveIcon />}
          >
            {t("Save")}
          </Button>
        )}
        {actions.length > 0 && (
          <Button
            onClick={handleActionsClick}
            variant="contained"
            color="warning"
            sx={{
              bgcolor: mapStyles.actions.warning,
            }}
            endIcon={<RocketLaunchIcon />}
          >
            {t("Actions")}
          </Button>
        )}
        {onClose && (
          <Button onClick={onClose} variant="contained" color="error">
            {t("Close")}
          </Button>
        )}
      </Box>
      <ExoAlert
        alert={alert}
        onSubmit={() => {
          onSave();
          setAlert(null);
        }}
        open={Boolean(alert)}
        onClose={() => setAlert(null)}
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box className="flex flex-col p-4 gap-2 w-60">
          <Typography variant="h3"> {t("Actions")}</Typography>
          {actions.map((action, index) => (
            <MapActionButton
              key={index}
              action={{
                ...action,
                onClick: () => {
                  action.onClick(mapElements, projectAreas);
                  setOpen(false);
                },
              }}
              icon={action.icon}
            />
          ))}
        </Box>
      </Dialog>
    </>
  );
};

const MapActionButton = ({ action = {} }) => {
  const mapStyles = useMapElementStyles();
  return (
    <Tooltip title={action.tooltip}>
      <Button
        onClick={action.onClick}
        endIcon={action.icon}
        variant={action.variant}
        color={action.color}
        sx={{
          bgcolor:
            action.variant !== "outlined" && mapStyles.actions[action.color],
        }}
      >
        {action.label}
      </Button>
    </Tooltip>
  );
};
