import { InputAdornment, TextField } from "@mui/material";
import { useEffect, useState } from "react";

const TypeNumber = ({
  field,
  dataCollection,
  errors = {},
  updateValidationOnChange,
}) => {
  function handleTextChange(value) {
    updateValidationOnChange(field.key, parseFloat(value.target.value));
  }

  return (
    <TextField
      key={field.key}
      variant="filled"
      sx={{
        gridColumn: field.colspan
          ? `span ${field.colspan} / span ${field.colspan}`
          : "auto",
      }}
      type="number"
      label={field.label}
      value={dataCollection[field.key]}
      onChange={handleTextChange}
      error={Boolean(errors[field.key])}
      helperText={errors[field.key]}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{field.unit}</InputAdornment>
        ),
      }}
    />
  );
};

export default TypeNumber;
