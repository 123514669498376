import {
  Box,
  Checkbox,
  useTheme,
  FormControlLabel,
  FormControl,
} from "@mui/material";

import FormHelperText from "../FormHelperText";
import { tokens } from "../../../theme/theme";

const TypeCheckbox = ({
  field,
  dataCollection,
  errors,
  updateValidationOnChange,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  function handleChange(value) {
    updateValidationOnChange([field.key], value.target.checked);
  }
  return (
    <Box
      className="flex justify-start items-center"
      sx={{
        height: "53px",
      }}
    >
      <FormControlLabel
        label={field.label}
        control={
          <Checkbox
            onChange={handleChange}
            color="secondary"
            checked={dataCollection[field.key]}
          />
        }
      />
      <FormHelperText
        error={Boolean(errors[field.key])}
        helperText={errors[field.key]}
      />
    </Box>
  );
};

export default TypeCheckbox;
