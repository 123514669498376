import { createElement } from "react";
import TypeCheckbox from "./TypeCheckbox";
import { typeMap } from "../FormFields";
import { Box } from "@mui/material";

const TypeCheckNFields = ({
  field,
  dataCollection,
  errors,
  updateValidationOnChange,
}) => {
  return (
    <Box className="grid grid-cols-1 gap-2">
      <TypeCheckbox
        field={field}
        dataCollection={dataCollection}
        errors={errors}
        updateValidationOnChange={updateValidationOnChange}
      />
      {dataCollection[field.key] &&
        field.fields.map((newField) =>
          createElement(typeMap[newField.type], {
            field: newField,
            dataCollection,
            errors,
            updateValidationOnChange,
          })
        )}
    </Box>
  );
};

export default TypeCheckNFields;
