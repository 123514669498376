import { Box } from "@mui/material";
import { PropGroup } from "../propretiesComponents/PropGroup";
import { PropField } from "../propretiesComponents/PropField";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

export const EdgeProperties = ({
  element,
  onUpdate,
  onReset,
  mapElements,
  disabled,
  errors,
  setElementSelection,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    setElementSelection([]);
  }, []);

  function handlePropChange(newValue, propKey, catKey) {
    var newProps = [];
    element[catKey].forEach((prop) => {
      newProps.push({
        ...prop,
        value: prop.key === propKey ? newValue : prop.value,
      });
    });
    onUpdate(newProps, catKey);
  }

  return (
    <Box className="flex flex-col gap-2">
      <PropGroup label="Base" borderWidth={0}>
        <PropField
          label="ID"
          value={element.id}
          onChange={onUpdate}
          propKey="id"
          type="display"
          disabled={disabled}
          errors={errors}
        />
        <PropField
          label="NAME"
          value={element.name}
          onChange={onUpdate}
          propKey="name"
          type="number"
          disabled={disabled}
          errors={errors}
        />
      </PropGroup>
      <PropGroup label={t("Properties")}>
        {element.props &&
          element.props.map((prop, index) => (
            <PropField
              disabled={disabled}
              value={prop.value}
              label={prop.label}
              type={prop.type}
              propKey={prop.key}
              onChange={(value, key) => handlePropChange(value, key, "props")}
              key={prop.key}
              errors={errors}
              unit={prop.unit}
            />
          ))}
      </PropGroup>
      {element.calc && (
        <PropGroup label={t("Calculated")}>
          {element.calc.map((prop, index) => (
            <PropField
              disabled={disabled}
              value={prop.value}
              label={prop.label}
              type={prop.type}
              propKey={prop.key}
              onChange={(value, key) => handlePropChange(value, key, "calc")}
              key={prop.key}
              errors={errors}
              unit={prop.unit}
            />
          ))}
        </PropGroup>
      )}
    </Box>
  );
};
