import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme/theme";
import { Person } from "./topbar/Person";
import TopbarMenuItem from "./topbar/TopbarMenuItem";
import topbarItemListRight from "./topbar/topbarItemListRight";
import { Notification } from "./topbar/Notification";
import { useTranslation } from "react-i18next";

export const Topbar = ({ selectedProject }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const sidepanelWidth = "200px";

  return (
    <Box
      className="p-2 flex flex-row justify-between items-center"
      sx={{
        bgcolor: colors.contrast[100],
        boxShadow: ` 0 0 10px ${colors.contrast[700]}`,
        borderColor: colors.greenAccent[500],
        borderWidth: "0 0 2px 0",
      }}
    >
      <Box sx={{ width: sidepanelWidth }}></Box>
      {selectedProject && (
        <Box>
          <Typography>{selectedProject.name}</Typography>
          <Typography>{selectedProject.scenario.name}</Typography>
        </Box>
      )}

      <Box
        sx={{ width: sidepanelWidth }}
        className="float-right flex flex-row justify-end gap-4"
      >
        {topbarItemListRight.map((item, index) => (
          <TopbarMenuItem
            item={item}
            Icon={item.icon}
            onClick={item.onClick}
            key={index}
          />
        ))}
        <Notification />
        <Person />
      </Box>
    </Box>
  );
};

export default Topbar;
