import { Dialog } from "@mui/material";
import MapGridDesigner from "../../components/map-grid-designer/MapGridDesigner";
import { useTranslation } from "react-i18next";
import { DBRequest } from "../../api/dbRequest";
import { useState } from "react";
import Loader from "../../components/Loader";
import { LoadingBlur } from "../../components/LoadingBlur";

export const ProjectAreaDesigner = ({ open, project, onClose, onUpdate }) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  function handleSave(elements, newProjectAreas) {
    DBRequest({
      path: `projects/${project.id}`,
      method: "patch",
      data: { projectAreas: newProjectAreas },
      onResponse: onUpdate,
      onLoading: setIsLoading,
    });
  }
  return (
    <Dialog fullScreen open={open}>
      <LoadingBlur active={isLoading} />
      <MapGridDesigner
        startProjectAreas={project.projectAreas}
        tools={["clear", "projectArea"]}
        noResultMode
        actions={[]}
        onSave={handleSave}
        onClose={onClose}
      />
    </Dialog>
  );
};
