import {
  Alert,
  Avatar,
  Box,
  Button,
  Link,
  Popover,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme/theme";
import { useState } from "react";
import TopbarMenuItem from "./TopbarMenuItem";
import NotificationsIcon from "@mui/icons-material/Notifications";

export const Notification = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <Box>
      <TopbarMenuItem
        item={{
          label: "Notification",
        }}
        Icon={NotificationsIcon}
        onClick={handleClick}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="mt-4"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography variant="h4" textAlign="center" className="pt-4 pb-3">
          Notification
        </Typography>
        <Box
          className="flex flex-col gap-2 pb-4 px-4 overflow-y-auto relative"
          sx={{
            width: "400px",
            maxHeight: "600px",
          }}
        >
          <Alert severity="error">This is an error alert — check it out!</Alert>
          <Alert severity="warning">
            This is a warning alert — check it out!
          </Alert>
          <Alert severity="info">This is an info alert — check it out!</Alert>
          <Alert severity="success">
            This is a success alert — check it out!
          </Alert>
          <Alert severity="error">This is an error alert — check it out!</Alert>
          <Alert severity="warning">
            This is a warning alert — check it out!
          </Alert>
          <Alert severity="info">This is an info alert — check it out!</Alert>
          <Alert severity="success">
            This is a success alert — check it out!
          </Alert>
          <Alert severity="error">This is an error alert — check it out!</Alert>
          <Alert severity="warning">
            This is a warning alert — check it out!
          </Alert>
          <Alert severity="info">This is an info alert — check it out!</Alert>
          <Alert severity="success">
            This is a success alert — check it out!
          </Alert>
        </Box>
      </Popover>
    </Box>
  );
};
