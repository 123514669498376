import Keycloak from "keycloak-js";

const keycloak = new Keycloak({
  url: "https://sso.ziggurat.ai/auth/",
  realm: "Staging",
  clientId: "ziggurat",
  clientSecret: "mSwxboJymBmtk4o38hXk9e0ShiqwsUb5",
});

export const initKeycloak = async (onInit, onError) => {
  try {
    const authenticated = await keycloak.init({
      onLoad: "login-required",
    });
    onInit(authenticated);
    console.log(
      `User is ${authenticated ? "authenticated" : "not authenticated"}`
    );
    if (authenticated) {
      const token = keycloak.token;
      localStorage.setItem("jwtToken", token);
    }
  } catch (error) {
    onError(error);
  }
};
export default keycloak;
