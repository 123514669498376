import { Alert, Box, Button, Dialog, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ExoAlert = ({ onSubmit, open, onClose, alert }) => {
  const { t } = useTranslation();
  if (!alert) return;
  return (
    <Dialog open={Boolean(open)} onClose={onClose}>
      <Alert
        severity={alert.type || "warning"}
        className=" max-w-full w-96 flex flex-col items-center gap-2"
        sx={{
          ".MuiAlert-icon": {
            margin: 0,
            svg: {
              width: "50px",
              height: "50px",
            },
          },
        }}
      >
        <Typography> {alert.message}</Typography>

        <Box className="flex flex-row justify-between mt-4 w-full px-4">
          <Button onClick={onClose} variant="contained" color="error">
            {alert.cancleLabel || t("Cancle")}
          </Button>
          {onSubmit && (
            <Button variant="contained" onClick={onSubmit} color="success">
              {alert.submitLabel || t("Submit")}
            </Button>
          )}
        </Box>
      </Alert>
    </Dialog>
  );
};

export default ExoAlert;
