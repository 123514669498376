import { Dialog } from "@mui/material";
import ExoForm from "../../components/exo/ExoForm";
import { useTranslation } from "react-i18next";
import { DBRequest } from "../../api/dbRequest";
import { useState } from "react";
import { useParams } from "react-router-dom";

const NewScenario = ({ open, onClose, onCreate, startData }) => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const fields = [
    {
      label: t("Name"),
      type: "textBig",
      key: "name",
    },
  ];

  function handleSubmit(data) {
    const reqData = { ...startData, ...data, project_id: id };
    DBRequest({
      path: "scenarios",
      data: reqData,
      method: "post",
      onResponse: onCreate,
      onLoading: setIsLoading,
    });
  }

  return (
    <Dialog open={open}>
      <ExoForm
        isLoading={isLoading}
        onCancle={onClose}
        header={t("New Scenario")}
        fields={fields}
        onSubmit={handleSubmit}
        className="p-4 flex flex-col gap-4"
      />
    </Dialog>
  );
};

export default NewScenario;
