import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { PropLayout } from "../PropLayout";

export const PropTypeText = ({
  label,
  value,
  onChange,
  propKey,
  disabled,
  unit,
}) => {
  function handleChange(e) {
    onChange(e.target.value, propKey);
  }
  return (
    <TextField
      label={label}
      type="text"
      variant="filled"
      value={value}
      disabled={disabled}
      onChange={handleChange}
      InputProps={{
        endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
      }}
    />
  );
};
