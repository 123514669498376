import { Box, Typography, useTheme } from "@mui/material";
import { NavLink } from "react-router-dom";
import BugReportIcon from "@mui/icons-material/BugReport";
import { tokens } from "../../theme/theme";
import { useTranslation } from "react-i18next";

const TopbarMenuItem = ({ item, Icon = BugReportIcon, onClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  return (
    <NavLink
      onClick={onClick && onClick}
      to={!onClick && item.to}
      target={item.target}
    >
      <Box
        className=" flex flex-col justify-center items-center pt-1"
        sx={{
          ":hover": {
            cursor: "pointer",
            "& svg": {
              color: colors.greenAccent[500],
            },
          },
        }}
      >
        <Icon sx={{ color: colors.contrast[600], fontSize: "28px" }} />
        <Typography variant="h5">{t(item.label)}</Typography>
      </Box>
    </NavLink>
  );
};

export default TopbarMenuItem;
