import { useEffect, useState } from "react";
import { DBRequest } from "./dbRequest";

const useRequest = ({ path, method = "get", data = {}, debug }) => {
  const [apiData, setApiData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [serverError, setServerError] = useState(null);

  useEffect(() => {
    reloadApiData();
  }, [path]);

  function reloadApiData() {
    DBRequest({
      path,
      method,
      data,
      onResponse: handleResponse,
      onLoading: setIsLoading,
      onError: (error, status) => setServerError({ error, status }),
    });
  }

  function handleResponse(res) {
    if (debug) console.log(res);
    setApiData(res);
  }

  return {
    apiData,
    setApiData,
    isLoading,
    setIsLoading,
    serverError,
    reloadApiData,
  };
};

export default useRequest;
