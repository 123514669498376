import { Box, Paper, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme/theme";
import { useTranslation } from "react-i18next";
import { systemImgMap } from "../../components/project/projectSystemMap";
import { NavLink } from "react-router-dom";
import { PopSettings } from "../../components/PopSettings";
import DeleteIcon from "@mui/icons-material/Delete";
import { DBRequest } from "../../api/dbRequest";

export const ProjectGridItem = ({ item, onRemoved }) => {
  const { t } = useTranslation();

  const actions = [
    { label: t("delete"), onClick: handleDelete, icon: <DeleteIcon /> },
  ];
  function handleDelete() {
    DBRequest({
      path: `projects/${item.id}`,
      method: "delete",
      onResponse: () => onRemoved(item.id),
    });
  }

  return (
    <NavLink to={`/projects/${item.id}`} className="flex">
      <Paper
        className="cursor-pointer w-full p-3 hover:scale-105 relative"
        sx={{ transition: "transform 0.5s ease" }}
      >
        <PopSettings actions={actions} className="absolute right-2 top-2" />
        <img
          className="w-24 h-24 object-contain m-auto"
          src={systemImgMap[item.system]}
        />
        <Typography variant="h4" textAlign="center">
          {item.name}
        </Typography>
        <Typography variant="h5">{item.description}</Typography>
        <Box className="grid grid-cols-2 gap-10">
          <LabelNumber label={t("Scenarios")} number={item.scenarios.length} />
        </Box>
      </Paper>
    </NavLink>
  );
};

const LabelNumber = ({ label, number }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  return (
    <Box className="w-full">
      <Typography
        variant="h3"
        textAlign="center"
        sx={{ color: colors.contrast[700] }}
      >
        {number}
      </Typography>
      <Typography variant="h5" textAlign="center">
        {label}
      </Typography>
    </Box>
  );
};
