import { useEffect, useState } from "react";
import { CircleMarker, Polygon, useMap, useMapEvents } from "react-leaflet";
import useMapElementStyles from "../useMapElementStyles";
import ToolButton from "../tools/ToolButton";
import { v4 as uuidv4 } from "uuid";
import { calculatePolygonArea } from "../helperFunctions/calcPolygonArea";
import CursorBox from "../CursorBox";
import { findBiggestName } from "../helperFunctions/findBiggestName";

export const AreaTool = ({
  onAlert,
  mapElements,
  selectedElement,
  active,
  onClick,
  setMapElements,
  open,
  onProjectAreaCheck,
  type = {
    img: "/assets/svg/tools/population.svg",
    label: "Population",
    tool: "populationTool",
  },
  defaultProps,
}) => {
  const [positions, setPositions] = useState(null);
  const [cursorPosition, setCursorPosition] = useState(null);

  const mapStyles = useMapElementStyles();

  useEffect(() => {
    setPositions(null);
  }, [active]);

  // CLICK EVENTS
  const handleMapClick = (e) => {
    if (
      e.originalEvent.target.tagName === "BUTTON" ||
      e.originalEvent.target.tagName === "IMG"
    )
      return;
    if (!active) return;

    // get coordinates
    const lat = e.latlng.lat;
    const lng = e.latlng.lng;

    // area check
    if (!onProjectAreaCheck([lat, lng]))
      return onAlert(
        "No nodes can be placed outside the project area.",
        "warning"
      );

    // add to areaNodes
    const newPositions = positions || [];
    newPositions.push([lat, lng]);
    setPositions(newPositions);
  };

  function handleMouseMove(e) {
    const { lat, lng } = e.latlng;
    const newCursorPosition = [lat, lng];
    setCursorPosition(newCursorPosition);
  }

  function handleMapRightClick() {
    createPolygon();
    setPositions(null);
  }

  function createPolygon() {
    if (positions === null || positions.length < 3) return;
    const newArea = {
      id: uuidv4(),
      type: "area",
      isSaved: false,
      name: "A" + findBiggestName(mapElements) + 1,
      positions: positions,
      props: [
        {
          key: "area",
          label: "area",
          value: calculatePolygonArea(positions),
          type: "display",
        },
        ...defaultProps,
      ],
      calc: [],
    };
    setMapElements([...mapElements, newArea]);
  }
  // Access the Leaflet map instance
  useMapEvents({
    click: handleMapClick,
    mousemove: handleMouseMove,
    contextmenu: (e) => {
      if (!active) return;
      // Prevent the default context menu behavior
      e.originalEvent.preventDefault();
      handleMapRightClick(e);
    },
  });

  function buttonClick(e) {
    if (active && positions) {
      createPolygon();
    }
    onClick(e, type.tool);
  }

  // NODE
  const nodeRadius =
    mapStyles.areaNode.size[type.tool] || mapStyles.areaNode.size.default;

  const nodeColor =
    mapStyles.areaNode.colors[type.tool] || mapStyles.areaNode.colors.default;

  const nodeEventHandlers = {
    mousedown: (e) => {
      // Prevent the default context menu behavior
      e.originalEvent.preventDefault();
    },
  };

  // CALC current Area Size
  const [currentSize, setCurrentSize] = useState(0);
  useEffect(() => {
    if (cursorPosition && positions) updateAreaSize();
  }, [cursorPosition, positions]);
  function updateAreaSize() {
    setCurrentSize(calculatePolygonArea([...positions, cursorPosition]));
  }

  return (
    <>
      <ToolButton
        src={type.img}
        label={type.label}
        onClick={buttonClick}
        active={active}
        open={open}
      />

      {active && positions && (
        <>
          <CursorBox>{parseFloat(currentSize).toFixed(3)} m²</CursorBox>
          <Polygon
            key={positions}
            weight={mapStyles.area.size.preview}
            positions={[...(positions || []), cursorPosition || []]}
            color={mapStyles.area.colors.preview}
          />
          {positions.map((position, posIndex) => (
            <CircleMarker
              key={posIndex}
              center={position}
              radius={nodeRadius}
              color={nodeColor}
              eventHandlers={nodeEventHandlers}
            />
          ))}
        </>
      )}
    </>
  );
};
