import { Box, Dialog, Typography } from "@mui/material";
import ExoForm from "../../components/exo/ExoForm";
import * as Yup from "yup";
import { DBRequest } from "../../api/dbRequest";
import { useTranslation } from "react-i18next";

const NewProject = ({ open = true, onClose, onCreate }) => {
  const { t } = useTranslation();
  function handleSubmit(data) {
    DBRequest({
      path: "projects",
      method: "post",
      data,
      onResponse: onCreate,
    });
  }

  const fields = [
    {
      label: t("Name"),
      type: "textBig",
      key: "name",
    },
    {
      label: t("Description"),
      type: "textBig",
      key: "description",
    },
    {
      label: t("System"),
      type: "cards",
      key: "system",
      options: [
        {
          label: t("Sewage"),
          value: 0,
          component: SystemCard,
          image: "/assets/png/sewage.png",
        },
        {
          label: t("Storm Water"),
          value: 1,
          component: SystemCard,
          image: "/assets/png/storm-water.png",
        },
        {
          label: t("Combined"),
          value: 2,
          component: SystemCard,
          image: "/assets/png/combined.png",
        },
      ],
    },
  ];
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    system: Yup.string().required("System is required"),
  });
  return (
    <Dialog open={open}>
      <Box className="p-4">
        <ExoForm
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          fields={fields}
          header="New Project"
          onCancle={onClose}
        />
      </Box>
    </Dialog>
  );
};

const SystemCard = ({ item, selected }) => {
  return (
    <Box className="w-32 flex flex-col gap-6 justify-center items-center">
      <img
        src={item.image}
        className="w-28 aspect-square object-contain object-center"
        style={{
          filter: selected ? "saturate(100%)" : "saturate(0%)",
        }}
      />
      <Typography variant="h4" textAlign="center">
        {item.label}
      </Typography>
    </Box>
  );
};

export default NewProject;
