import { Box, Button, Popover, Tooltip, useTheme } from "@mui/material";
import { tokens } from "../../../theme/theme";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

export const MapButton = ({
  Icon,
  tooltip,
  size = 25,
  color,
  sx = {},
  className = "",
  onClick = () => {},
  popover,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? uuidv4() : undefined;

  return (
    <>
      <Tooltip title={tooltip} placement="right">
        <Button
          onClick={popover ? handleClick : onClick}
          sx={{
            borderRadius: "5px",
            padding: "5px",
            aspectRatio: "1",
            minWidth: "1px",
            ...sx,
          }}
          className={className}
          variant="contained"
        >
          <Icon
            sx={{ color: color || colors.primary[400], fontSize: `${size}px` }}
          />
        </Button>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: "right",
          vertical: "left",
        }}
        className="ml-2"
      >
        <Box className="p-2">{popover}</Box>
      </Popover>
    </>
  );
};
