import { Box, Button, Dialog, Typography } from "@mui/material";
import { useState } from "react";
import { HexColorPicker } from "react-colorful";

const ExoColorPicker = ({
  color = "",
  open,
  onClose = () => {},
  onSelect = () => {},
}) => {
  const [selectedColor, setSelectedColor] = useState(color);

  function handleColorChange(hex) {
    setSelectedColor(hex);
  }

  function handleSelect() {
    onSelect(selectedColor);
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Box className="flex flex-col justify-center items-center gap-4">
        <Typography variant="h3">Pick a Color</Typography>
        <HexColorPicker color={selectedColor} onChange={handleColorChange} />
        <Box className="flex flex-row justify-between w-full">
          <Button onClick={onClose}>Cancle</Button>
          <Button variant="contained" onClick={handleSelect}>
            Select
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ExoColorPicker;
