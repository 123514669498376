import { Box } from "@mui/material";
import TypeText from "./TypeText";

export const TypeCoordinates = ({
  field,
  dataCollection,
  errors = {},
  updateValidationOnChange,
}) => {
  function handleChange(prop, value) {
    updateValidationOnChange([field.key], {
      ...dataCollection[field.key],
      [prop]: value,
    });
  }
  return (
    <Box className="flex flex-col gap-1">
      <TypeText
        updateValidationOnChange={handleChange}
        dataCollection={
          dataCollection[field.key] ? dataCollection[field.key] : {}
        }
        field={{ label: "LAT", key: "lat", type: "text" }}
      />
      <TypeText
        updateValidationOnChange={handleChange}
        dataCollection={
          dataCollection[field.key] ? dataCollection[field.key] : {}
        }
        field={{ label: "LNG", key: "lng", type: "text" }}
      />
    </Box>
  );
};
