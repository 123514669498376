import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import FormHelperText from "../FormHelperText";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import useRequest from "../../../api/useRequest";

const TypeCostFunction = ({
  field,
  dataCollection,
  errors = {},
  updateValidationOnChange,
}) => {
  const { t } = useTranslation();
  const { apiData } = useRequest({ path: "cost_functions", debug: true });

  const [value, setValue] = useState("");

  function handleChange(e) {
    updateValidationOnChange(field.key, e.target.value);
    setValue(e.target.value);
  }

  useEffect(() => {
    if (dataCollection[field.key]) setValue(dataCollection[field.key]);
  }, [dataCollection[field.key]]);

  return (
    <FormControl fullWidth variant="filled">
      <InputLabel id="custom-select-label">{field.label}</InputLabel>
      <Select
        labelId="custom-select-label"
        value={value}
        label={field.label}
        onChange={handleChange}
        error={Boolean(errors[field.key])}
      >
        {apiData &&
          apiData.cost_functions.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText
        error={Boolean(errors[field.key])}
        helperText={errors[field.key]}
      />
    </FormControl>
  );
};

export default TypeCostFunction;
