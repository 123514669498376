import { Polygon } from "react-leaflet";
import useMapElementStyles from "../useMapElementStyles";
import { useEffect, useRef, useState } from "react";
import ProjectAreaNode from "./ProjectAreaNode";
import { deleteMapElementById } from "../helperFunctions/deleteMapElementById";

export const ProjectArea = ({
  elements,
  currentMode,
  onSelect,
  selectedElement,
  projectAreas,
  setProjectAreas,
}) => {
  // styles
  const mapStyles = useMapElementStyles();

  const weight =
    mapStyles.projectArea.size[currentMode] ||
    mapStyles.projectArea.size.default;

  const bgColor = mapStyles.projectArea.bgColors.default;
  const color = mapStyles.projectArea.colors.default;

  const dashArray = mapStyles.projectArea.dashArray;
  const fillOpacity =
    mapStyles.projectArea.fillOpacity[currentMode] ||
    mapStyles.projectArea.fillOpacity.default;

  const [key, setKey] = useState(0);

  // polygon to cover the entire world
  const entireWorld = [
    [90, -180],
    [90, 180],
    [-90, 180],
    [-90, -180],
  ];

  const [positions, setPositions] = useState([]);

  useEffect(() => {
    setKey(key + 1);

    // create positionsarray
    const newPositions = [];
    elements.forEach((element) => {
      newPositions.push(element.positions);
    });
    setPositions(newPositions);
  }, [projectAreas, currentMode, selectedElement]);

  // shortcuts
  useEffect(() => {
    // Add an event listener for the "keydown" event
    document.addEventListener("keydown", handleDeleteShortcut);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleDeleteShortcut);
    };
  }, []);

  const handleDeleteShortcut = (event) => {
    // Check if the pressed key is the "Entf" (Delete) key
    if (event.key === "Delete") {
      if (!selectedElement.id) return;
      deleteMapElementById(setProjectAreas, selectedElement.id);
    }
  };

  return (
    <>
      <Polygon
        key={key}
        positions={positions.length && [entireWorld, positions]}
        fillOpacity={fillOpacity}
        weight={weight}
        color={color}
        fillColor={bgColor}
        dashArray={dashArray}
      />
      {elements.map((element, index) => (
        <>
          {element.positions.map((position, posIndex) => (
            <ProjectAreaNode
              key={`${element.id}-${posIndex}`}
              element={{ position: position, id: element.id }}
              polygon={element}
              currentMode={currentMode}
              projectAreas={projectAreas}
              setProjectAreas={setProjectAreas}
              selectedElement={selectedElement}
              onSelect={onSelect}
              index={posIndex}
            />
          ))}
        </>
      ))}
    </>
  );
};
