import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const DateLabel = ({ date }) => {
  const [dateLabel, setDateLabel] = useState("");
  const { t } = useTranslation();
  useEffect(() => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setDateLabel(formattedDate);
  }, [date]);

  return <>{dateLabel === "Invalid date" ? t("Infinite") : t(dateLabel)}</>;
};
