import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import FormHelperText from "../FormHelperText";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";

import LocalizedStrings from "react-localization";
let L = new LocalizedStrings({
  en: {
    Start: "Start",
    End: "End",
  },
  de: {
    Start: "Start",
    End: "Ende",
  },
});
L.setLanguage("de");

const moment = require("moment");

const TypeDateRange = ({
  field,
  dataCollection,
  errors = {},
  updateValidationOnChange,
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const format = "DD.MM.YYYY";

  useEffect(() => {
    // init moment date objects
    setStartDate(moment(dataCollection[`${field.key}Start`], format));
    setEndDate(moment(dataCollection[`${field.key}End`], format));
  }, []);

  function handleChangeStart(value) {
    setStartDate(value);
    updateValidationOnChange([`${field.key}Start`], value.format(format));
  }
  function handleChangeEnd(value) {
    setEndDate(value);
    updateValidationOnChange([`${field.key}End`], value.format(format));
  }
  return (
    <Box>
      <Box className="flex flex-row gap-2 w-full items-center">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            className="w-full"
            onChange={handleChangeStart}
            value={startDate}
            format={format}
            label={`${field.label} ${L.Start}`}
          />
          -
          <DatePicker
            className="w-full"
            onChange={handleChangeEnd}
            value={endDate}
            format={format}
            label={`${field.label} ${L.End}`}
          />
        </LocalizationProvider>
      </Box>
      <FormHelperText
        error={Boolean(errors[field.key])}
        helperText={errors[field.key]}
      />
    </Box>
  );
};

export default TypeDateRange;
