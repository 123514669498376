import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import ExoAvatar from "../../components/exo/ExoAvatar";
import { DateLabel } from "../../components/label/DateLabel";
import { PopSettings } from "../../components/PopSettings";
import DeleteIcon from "@mui/icons-material/Delete";
import { DBRequest } from "../../api/dbRequest";

export const ScenarioListItem = ({ item, onRemoved }) => {
  const { t } = useTranslation();

  const user = item.user;

  const actions = [
    { label: t("delete"), onClick: handleDelete, icon: <DeleteIcon /> },
  ];
  function handleDelete() {
    DBRequest({
      path: `scenarios/${item.id}`,
      method: "delete",
      onResponse: () => onRemoved(item.id),
    });
  }
  return (
    <tr>
      <td className="px-4">
        <NavLink to={`/scenarios/${item.id}`}>
          <Typography
            variant="h4"
            className="flex gap-2 w-full justify-start items-center py-2"
          >
            {item.name}
          </Typography>
        </NavLink>
      </td>
      <td className="px-4 align-middle">
        <Typography className="flex justify-start items-center gap-2">
          <ExoAvatar
            size="40px"
            picture={user.profile_picture_url}
            name={`${user.lastName} ${user.firstName}`}
          />
          {`${user.lastName} ${user.firstName}`}
        </Typography>
      </td>
      <td className="px-4 align-middle">
        <Typography textAlign="left">
          {item.modified_at && <DateLabel date={item.modified_at} />}
        </Typography>
      </td>
      <td>
        <PopSettings actions={actions} />
      </td>
    </tr>
  );
};
