import {
  Box,
  Button,
  Dialog,
  IconButton,
  InputBase,
  useTheme,
  Typography,
  Divider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import { tokens } from "../../../theme/theme";
import { DBRequest } from "../../../api/dbRequest";
import { MapButton } from "./MapButton";
import { useMap } from "react-leaflet";

export const MapSearch = () => {
  //https://nominatim.openstreetmap.org/search?q=New+York&format=json
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [response, setResponse] = useState([]);
  const map = useMap();

  function handleSearch() {
    DBRequest({
      baseUrl: "https://nominatim.openstreetmap.org/search?format=json&q=",
      path: searchText,
      onResponse: handleResponse,
    });
  }
  function handleResponse(data) {
    setResponse(data);
  }

  function handleSelect(location) {
    const newCenter = [location.lat, location.lon]; // New center coordinates
    map.setView(newCenter, 13);
    setOpen(false);
  }

  // press enter

  return (
    <>
      <MapButton
        tooltip="Search Location"
        Icon={SearchIcon}
        onClick={() => setOpen(true)}
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box className=" flex flex-col gap-2">
          <Box
            className="top-0 left-0 w-full h-full flex justify-center items-center"
            sx={{
              borderBottom: "1px solid",
              borderColor: colors.contrast[300],
            }}
          >
            <InputBase
              sx={{
                ml: 1,
                flex: 1,
                width: "500px",
                maxWidth: "90%",
              }}
              placeholder="Search Maps"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              inputProps={{ "aria-label": "search google maps" }}
            />
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={handleSearch}
            >
              <SearchIcon />
            </IconButton>
          </Box>
          {response.length > 0 && (
            <Box
              className="flex flex-col gap-2 px-2 pb-2 overflow-y-auto"
              sx={{ maxHeight: "60vh" }}
            >
              {response.map((res, index) => (
                <>
                  {index !== 0 ? <Divider /> : ""}
                  <SearchRes res={res} onClick={handleSelect} />
                </>
              ))}
            </Box>
          )}
        </Box>
      </Dialog>
    </>
  );
};

const SearchRes = ({ res, onClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box
      onClick={() => onClick(res)}
      className="p-2 rounded-lg cursor-pointer transition-colors"
      sx={{
        borderWidth: "1px",
        ":hover": {
          borderColor: colors.contrast[400],
          bgcolor: colors.contrast[300],
        },
      }}
    >
      <Typography>{res.display_name}</Typography>
    </Box>
  );
};
