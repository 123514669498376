import { Box, Typography } from "@mui/material";
import { SidebarMenuItem } from "./SidebarMenuItem";

export const SidebarMenuItemGroup = ({ group, open }) => {
  return (
    <Box className="flex flex-col gap-1 mt-4">
      <Typography variant="h6">{group.label}</Typography>

      {group.items.map((item, index) => (
        <SidebarMenuItem item={item} Icon={item.icon} open={open} key={index} />
      ))}
    </Box>
  );
};
