import {
  Avatar,
  Box,
  Button,
  Link,
  Popover,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme/theme";
import { useState } from "react";
import ExoAvatar from "../../components/exo/ExoAvatar";
import keycloak from "../../security/keycloak";

export const Person = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box>
      <ExoAvatar
        picture="/assets/png/mock-person.png"
        size={50}
        onClick={handleClick}
      />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="mt-4"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box className="flex flex-col gap-2 items-center p-4">
          <Typography variant="h5">Email</Typography>
          <ExoAvatar picture="/assets/png/mockPerson.png" size={130} />
          <Box className="flex flex-col justify-center w-full ">
            <Typography variant="h4" textAlign="center">
              Name
            </Typography>
            <Typography variant="h5" textAlign="center">
              Position
            </Typography>
          </Box>
          <Box className="flex flex-row gap-4 w-full justify-between mt-5">
            <Link
              target="_blank"
              href="https://sso.ziggurat.ai/auth/realms/Staging/account/"
            >
              <Button variant="contained">Settings</Button>
            </Link>
            <Button
              variant="contained"
              color="secondary"
              onClick={keycloak.logout}
            >
              Logout
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};
