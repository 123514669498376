import { Skeleton } from "@mui/material";

const ExoSkeleton = ({ active, n = 1, className, sx = {} }) => {
  if (!active) return;
  return (
    <>
      {Array(n)
        .fill(null)
        .map((_, index) => (
          <Skeleton
            key={index}
            className={className}
            sx={{
              padding: 0,
              margin: 0,
              transform: "unset",
              borderRadius: "4px",
              ...sx,
            }}
          />
        ))}
    </>
  );
};

export default ExoSkeleton;
