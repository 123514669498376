import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useEffect, useState } from "react";

const moment = require("moment");

const TypeDate = ({
  field,
  updateValidationOnChange,
  dataCollection,
  errors,
}) => {
  const [date, setDate] = useState(null);
  const format = "DD.MM.YYYY";

  useEffect(() => {
    if (field.date === "today" && !dataCollection[field.key]) {
      const currentMoment = moment();
      setDate(currentMoment);
      updateValidationOnChange(field.key, currentMoment.format(format));
    } else {
      if (dataCollection[field.key]) {
        setDate(moment(dataCollection[field.key], format));
      }
    }
  }, []);

  // handle input changes
  function handleDateChange(newDate) {
    updateValidationOnChange(field.key, newDate.format(format));
    setDate(newDate);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        className="w-full"
        onChange={handleDateChange}
        value={date}
        format={format}
        label={field.label}
      />
    </LocalizationProvider>
  );
};

export default TypeDate;
