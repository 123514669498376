export const defaultNodeProps = [
  {
    type: "checkbox",
    label: "Outlet",
    value: false,
    key: "outlet_candidate",
  },
  {
    type: "checkbox",
    label: "Existing Manhole",
    value: false,
    key: "existing_manhole",
  },
  {
    type: "checkbox",
    label: "Lift station",
    value: false,
    key: "lift_station",
  },
];
