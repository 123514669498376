import { useState } from "react";
import { useParams } from "react-router-dom";
import { TitleCard } from "./cards/TitleCard";
import CardGrid from "../../components/cards/CardGrid";
import SkeletonCard from "../../components/cards/SkeletonCard";
import MockProjectAreas from "../designer/dummyProjectArea.json";
import CardContainer from "../../components/cards/CardContainer";
import { LayoutShifter } from "../../components/layouts/LayoutShifter";
import { useTranslation } from "react-i18next";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import { GridLayout } from "../../components/layouts/GridLayout";
import { ListLayout } from "../../components/layouts/ListLayout";
import { ScenarioGridItem } from "./ScenarioGridItem";
import { ScenarioListItem } from "./ScenarioListItem";
import useRequest from "../../api/useRequest";
import { CreateProjectAreaCard } from "./cards/CreateProjectAreaCard";
import { ProjectAreaDesigner } from "./ProjectAreaDesigner";
import { MapCard } from "./cards/MapCard";
import NewScenario from "./NewScenario";
import { updateOrCreate } from "../../components/updateOrCreate";

const Project = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [openProjectArea, setOpenProjectArea] = useState(false);
  const { apiData, setApiData } = useRequest({
    path: `projects/${id}`,
    debug: true,
  });

  function handleUpdatedProject(data) {
    setApiData(data);
    setOpenProjectArea(false);
  }

  // new scenario
  const [open, setOpen] = useState(false);
  function handleCreatedScenario(data) {
    setOpen(false);
    setApiData({
      ...apiData,
      scenarios: updateOrCreate(apiData.scenarios, data),
    });
  }

  // layout
  const layouts = [
    {
      tooltip: "Grid",
      icon: <ViewModuleIcon />,
      Item: ScenarioGridItem,
      layout: GridLayout,
    },
    {
      tooltip: "List",
      icon: <ViewListIcon />,
      Item: ScenarioListItem,
      layout: ListLayout,
    },
  ];
  const header = [
    {
      label: t("Name"),
    },
    {
      label: t("Created By"),
    },
    {
      label: t("Last Change"),
    },
    {
      label: "",
    },
  ];

  const actions = [
    {
      label: t("New Scenario"),
      onClick: () => setOpen(true),
      color: "success",
      variant: "outlined",
    },
  ];

  function handleScenarioChange(newScenarios) {
    setApiData({ ...apiData, scenarios: newScenarios });
  }

  if (!apiData) return <Skeleton />;
  return (
    <CardGrid>
      <TitleCard project={apiData} />
      {apiData.projectAreas ? (
        <MapCard
          project={apiData}
          onOpenEditor={() => setOpenProjectArea(true)}
        />
      ) : (
        <CreateProjectAreaCard setOpenProjectArea={setOpenProjectArea} />
      )}
      <CardContainer span={99999}>
        <LayoutShifter
          onChange={handleScenarioChange}
          header={header}
          items={apiData.scenarios}
          label={t("Szenarios")}
          layouts={layouts}
          actions={actions}
        />
      </CardContainer>
      <ProjectAreaDesigner
        project={apiData}
        open={openProjectArea}
        onClose={() => setOpenProjectArea(false)}
        onUpdate={handleUpdatedProject}
        projectAreas={apiData.projectAreas}
      />
      <NewScenario
        open={open}
        onClose={() => setOpen(false)}
        onCreate={handleCreatedScenario}
      />
    </CardGrid>
  );
};

const Skeleton = () => {
  return (
    <CardGrid>
      <SkeletonCard height={400} />
      <SkeletonCard
        span={3}
        height={400}
        containerClassName="col-span-1 lg:col-span-2 xl:col-span-3 2xl:col-span-4"
      />
      <SkeletonCard span={5} height={400} />
    </CardGrid>
  );
};
export default Project;
