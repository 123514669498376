import Typography from "@mui/material/Typography";
import useMapElementStyles from "../useMapElementStyles";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../theme/theme";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useTranslation } from "react-i18next";

export const PropGroup = ({ label, onReset, borderWidth = 2, children }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const styles = useMapElementStyles();

  const { t } = useTranslation();

  return (
    <Accordion elevation={0}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography variant="h6" sx={{ color: colors.contrast[900] }}>
          {label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          className="flex flex-row justify-between pt-2 px-3"
          sx={{
            borderTop: `${borderWidth}px solid ${colors.contrast[300]}`,
          }}
        >
          {onReset && (
            <Typography
              sx={{ color: styles.selected }}
              variant="h5"
              onClick={onReset}
              className=" cursor-pointer"
            >
              {t("Reset")}
            </Typography>
          )}
        </Box>
        <Box className="flex flex-col gap-2 px-5 pb-4 pt-1">{children}</Box>
      </AccordionDetails>
    </Accordion>
  );
};
