export const defaultAreaProps = [
  {
    type: "display",
    label: "Area Type",
    value: "population",
    key: "areaType",
  },
  {
    type: "nodeSelection",
    label: "Nodes",
    value: [],
    key: "nodes",
    unit: "-",
  },
  {
    type: "number",
    value: "0",
    label: "First year density",
    key: "start_year_density",
    unit: "pop/km^2",
  },
  {
    type: "number",
    value: "0",
    label: "Last year density",
    key: "final_year_density",
    unit: "pop/km^2",
  },
];
