import { useEffect } from "react";
import ToolButton from "./ToolButton";

const DeleteTool = ({
  mapElements,
  selectedElement,
  active,
  onClick,
  setMapElements,
  onAlert,
  open,
  setSelectedElement,
}) => {
  useEffect(() => {
    if (active) handleDelete(selectedElement);
  }, [selectedElement]);

  useEffect(() => {
    if (active) setSelectedElement({});
  }, [active]);

  function handleDelete(elementToDelete) {
    const newMapElements = mapElements.filter(
      (element) => element.id !== elementToDelete.id
    );

    if (
      elementToDelete.type !== "node" ||
      !existingEdgeConstraints(elementToDelete.id)
    ) {
      setMapElements(newMapElements);
    }
  }

  function existingEdgeConstraints(id) {
    const hasConstraints = mapElements.some(
      (mapElement) =>
        mapElement.type === "edge" &&
        (mapElement.startNodeId === id || mapElement.endNodeId === id)
    );
    if (hasConstraints)
      onAlert(
        "An edge has this knot attached. Disconnect the knot from the edge first.",
        "warning"
      );
    return hasConstraints;
  }
  return (
    <ToolButton
      src={"/assets/svg/tools/erase.svg"}
      label="Erase"
      onClick={(e) => onClick(e, "delete")}
      active={active}
      open={open}
    />
  );
};
export default DeleteTool;
