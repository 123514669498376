import React, { useState, useEffect } from "react";
import { Snackbar, SnackbarContent, IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

const Alert = ({ alert, setAlert }) => {
  const handleClose = () => {
    setAlert({ ...alert, alertOpen: false });
  };

  const { alertOpen, alertType, alertText } = alert;

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={alertOpen}
      autoHideDuration={6000} // Adjust as needed
      onClose={handleClose}
    >
      <SnackbarContent
        style={{
          backgroundColor: alertType === "success" ? "#4CAF50" : "#f44336",
        }}
        message={<span>{alertText}</span>}
        action={
          <IconButton size="small" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Snackbar>
  );
};

export default Alert;
