import { Box, useTheme } from "@mui/material";
import Div100vh from "react-div-100vh";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { tokens } from "../theme/theme";
import { ColorPreview } from "../theme/ColorShades";

const Layout = ({ children }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const mode = theme.palette.mode;

  return (
    <Div100vh>
      <Box
        className="flex flex-row w-full h-full"
        sx={{
          bgcolor: colors.contrast[100],
        }}
      >
        <Sidebar />
        <Box className="flex flex-col w-full h-full z-0">
          <Topbar />
          <main className="h-full w-full overflow-auto flex flex-col p-6 relative">
            {children}
          </main>
        </Box>
      </Box>
    </Div100vh>
  );
};

export default Layout;
