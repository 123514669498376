import { Box, Paper, Typography } from "@mui/material";
import NewProject from "./NewProject";
import { useEffect, useState } from "react";
import { DBRequest } from "../../api/dbRequest";
import { LayoutShifter } from "../../components/layouts/LayoutShifter";
import { useTranslation } from "react-i18next";
import { ProjectGridItem } from "./ProjectGridItem";
import { GridLayout } from "../../components/layouts/GridLayout";
import { ProjectListItem } from "./ProjectListItem";
import { ListLayout } from "../../components/layouts/ListLayout";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import useRequest from "../../api/useRequest";
import { updateOrCreate } from "../../components/updateOrCreate";

const Projects = () => {
  const { t } = useTranslation();

  const { apiData, setApiData, isLoading } = useRequest({ path: "projects" });

  // new project
  const [open, setOpen] = useState(false);
  function handleCreatedProject(data) {
    setOpen(false);
    setApiData({
      ...apiData,
      projects: updateOrCreate(apiData.projects, data),
    });
  }

  // layout
  const header = [
    {
      label: t("Title"),
    },
    {
      label: t("Description"),
    },
    {
      label: t("Scenarios"),
    },
    {
      label: "",
    },
  ];

  function handleProjectsChange(newProjects) {
    setApiData({ ...apiData, projects: newProjects });
  }

  const layouts = [
    {
      tooltip: "Grid",
      icon: <ViewModuleIcon />,
      Item: ProjectGridItem,
      layout: GridLayout,
    },
    {
      tooltip: "List",
      icon: <ViewListIcon />,
      Item: ProjectListItem,
      layout: ListLayout,
    },
  ];

  return (
    <Box>
      <Box className="flex flex-col gap-4">
        <Typography variant="h3">{t("Start New Project")}</Typography>
        <Box className="flex">
          <Box
            className="flex flex-col gap-2 items-center cursor-pointer"
            onClick={() => setOpen(true)}
          >
            <Paper className="p-5">
              <img className="w-12" src={"/assets/png/new-project.png"} />
            </Paper>
            <Typography variant="h4">{t("New Project")}</Typography>
          </Box>
        </Box>
        <LayoutShifter
          onChange={handleProjectsChange}
          layouts={layouts}
          items={apiData && apiData.projects}
          label={t("Current Projects")}
          header={header}
          isLoading={isLoading}
        />
      </Box>
      <NewProject
        open={open}
        onClose={() => setOpen(false)}
        onCreate={handleCreatedProject}
      />
    </Box>
  );
};

export default Projects;
