import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../theme/theme";

const TypeDivider = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  return (
    <Box
      className="m-auto rounded-full "
      sx={{ backgroundColor: colors.primary[300], height: "2px", width: "98%" }}
    ></Box>
  );
};
export default TypeDivider;
