import L from "leaflet";
import { useEffect } from "react";
import { useMapEvents } from "react-leaflet";

export const NodeCursor = ({
  featureGroupRef,
  mapElements,
  color = "red",
  onProjectAreaCheck,
}) => {
  var isVisible = false;

  useMapEvents({
    mousemove: handleMouseMove,
  });

  useEffect(() => {
    if (!featureGroupRef.current) return;
    // Clear existing feature layer on the map
    featureGroupRef.current.clearLayers();
    addCircleLayer();
  }, [featureGroupRef.current, mapElements]);

  // Render circle at the position of the cursor when the map is clicked
  const cursorCircle = L.circleMarker([0, 0], {
    radius: 5,
    color: color,
    fillOpacity: 0.5,
  });

  function handleMouseMove(e) {
    const { lat, lng } = e.latlng;

    if (!onProjectAreaCheck([lat, lng])) return clearCircleLayer();
    if (!isVisible) addCircleLayer();
    cursorCircle.setLatLng([lat, lng]);
  }

  function addCircleLayer() {
    featureGroupRef.current.addLayer(cursorCircle);
    isVisible = true;
  }

  function clearCircleLayer() {
    featureGroupRef.current.clearLayers();
    isVisible = false;
  }

  return;
};
