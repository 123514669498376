import { Box, Paper, Typography } from "@mui/material";
import { systemImgMap } from "../../components/project/projectSystemMap";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { PopSettings } from "../../components/PopSettings";
import DeleteIcon from "@mui/icons-material/Delete";
import { DBRequest } from "../../api/dbRequest";

export const ProjectListItem = ({ item, onRemoved }) => {
  const { t } = useTranslation();

  const actions = [
    { label: t("delete"), onClick: handleDelete, icon: <DeleteIcon /> },
  ];
  function handleDelete() {
    DBRequest({
      path: `projects/${item.id}`,
      method: "delete",
      onResponse: () => onRemoved(item.id),
    });
  }

  return (
    <tr>
      <td className="px-4">
        <NavLink to={`/projects/${item.id}`}>
          <Typography
            variant="h4"
            className="flex gap-2 w-full justify-start items-center"
          >
            <img
              className="w-10 h-10 object-contain"
              src={systemImgMap[item.system]}
            />
            {item.name}
          </Typography>
        </NavLink>
      </td>
      <td className="px-4 align-middle">
        <Typography variant="h5" textAlign="left">
          {item.description}
        </Typography>
      </td>
      <td className="px-4 align-middle">
        <Typography textAlign="left">{item.scenarios.length}</Typography>
      </td>
      <td>
        <PopSettings actions={actions} />
      </td>
    </tr>
  );
};
