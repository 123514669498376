export function updateOrCreate(data, updatedOrNewElement) {
  var newElements = [...data];

  const existing = newElements.find((obj) => obj.id === updatedOrNewElement.id);
  if (existing) {
    newElements.forEach((element, index) => {
      if (element.id === updatedOrNewElement.id) {
        newElements[index] = updatedOrNewElement;
      }
    });
  } else {
    newElements.unshift(updatedOrNewElement);
  }

  return [...newElements];
}
