import { Box, TextField, Typography } from "@mui/material";
import { PropLayout } from "../PropLayout";

export const PropTypeDisplay = ({ label, value }) => {
  return (
    <TextField
      label={label}
      type="text"
      variant="filled"
      value={value}
      disabled
    />
  );
};
