import { useMapEvents } from "react-leaflet";
import { v4 as uuidv4 } from "uuid";
import { NodeCursor } from "./NodeCursor";
import useMapElementStyles from "../useMapElementStyles";
import ToolButton from "../tools/ToolButton";
import { updateMapElementById } from "../helperFunctions/updateMapElementById";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { findBiggestName } from "../helperFunctions/findBiggestName";

const NodeTool = ({
  mapElements,
  setMapElements,
  active,
  onClick,
  featureGroupRef,
  onProjectAreaCheck,
  onAlert,
  open,
  defaultProps,
}) => {
  const { t } = useTranslation();
  const mapStyles = useMapElementStyles();
  const [key, setKey] = useState(0);
  // CLICK EVENTS
  const handleMapClick = (e) => {
    if (
      e.originalEvent.target.tagName === "BUTTON" ||
      e.originalEvent.target.tagName === "IMG"
    )
      return;
    if (!active) return;
    // get coordinates
    const lat = e.latlng.lat;
    const lng = e.latlng.lng;
    if (!onProjectAreaCheck([lat, lng]))
      return onAlert(
        t("No nodes can be placed outside the project area."),
        "warning"
      );
    // build new nodes object
    const newNode = {
      type: "node",
      isSaved: false,
      name: findBiggestName(mapElements) + 1,
      position: [lat, lng],
      id: uuidv4(),
      props: defaultProps,
    };
    setMapElements(updateMapElementById(mapElements, newNode));
  };

  function handleMapRightClick(e) {
    onClick(e, "nodeTool");
  }

  useMapEvents({
    click: handleMapClick,
    contextmenu: (e) => {
      if (!active) return;
      // Prevent the default context menu behavior
      e.originalEvent.preventDefault();
      handleMapRightClick(e);
    },
  });

  return (
    <>
      <ToolButton
        src={"/assets/svg/tools/node.svg"}
        label={t("Node")}
        onClick={(e) => onClick(e, "nodeTool")}
        active={active}
        open={open}
      />
      {active && (
        <NodeCursor
          key={key}
          mapElements={mapElements}
          featureGroupRef={featureGroupRef}
          color={mapStyles.node.colors.preview}
          onProjectAreaCheck={onProjectAreaCheck}
        />
      )}
    </>
  );
};

export default NodeTool;
