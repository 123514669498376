import React, { createElement } from "react";
import { PropTypeText } from "./fieldTypes/PropTypeText";
import { PropTypeNumber } from "./fieldTypes/PropTypeNumber";
import { PropTypeDisplay } from "./fieldTypes/PropTypeDisplay";
import { PropTypePositions } from "./fieldTypes/PropTypePositions";
import { PropTypeLabel } from "./fieldTypes/PropTypeLabel";
import { PropTypeCheckbox } from "./fieldTypes/PropTypeCheckbox";
import { PropTypeNodeSelection } from "./fieldTypes/PropTypeNodeSelection";

export const PropField = ({
  value,
  label,
  type,
  onChange,
  propKey,
  disabled,
  errors,
  setCurrentMode,
  elementSelection,
  setElementSelection,
  currentMode,
  unit,
}) => {
  if (!propTypeMap[type]) return;
  return createElement(propTypeMap[type], {
    value,
    disabled,
    label,
    onChange,
    propKey,
    errors: errors || {},
    setCurrentMode,
    elementSelection,
    setElementSelection,
    currentMode,
    unit,
  });
};

const propTypeMap = {
  text: PropTypeText,
  number: PropTypeNumber,
  display: PropTypeDisplay,
  positions: PropTypePositions,
  label: PropTypeLabel,
  checkbox: PropTypeCheckbox,
  Boolean: PropTypeCheckbox,
  nodeSelection: PropTypeNodeSelection,
};
