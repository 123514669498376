export const validateDataForOptimization = (mapElements) => {
  const validation = {
    outletCandidate: false,
  };

  mapElements.forEach((element) => {
    if (!validation.outletCandidate && element.type === "node") {
      element.props.some((prop) => {
        if (prop.key === "outlet_candidate" && prop.value) {
          validation.outletCandidate = true;
          return true; // stop iteration if condition is met
        }
      });
    }
  });

  return Object.values(validation).every((value) => value);
};
