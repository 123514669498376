import { Box, InputAdornment, TextField, Typography } from "@mui/material";

const TypeText = ({
  field,
  dataCollection,
  errors = {},
  updateValidationOnChange,
}) => {
  function handleTextChange(value) {
    updateValidationOnChange([field.key], value.target.value);
  }

  return (
    <Box className="flex flex-row gap-2 items-center pl-6">
      <Typography className="w-20" textAlign="left" variant="label">
        {field.label}
      </Typography>
      <TextField
        variant="filled"
        sx={{
          "& input": {
            padding: "5px 15px",
          },
          gridColumn: field.colspan
            ? `span ${field.colspan} / span ${field.colspan}`
            : "auto",
        }}
        value={dataCollection[field.key] || ""}
        onChange={handleTextChange}
        multiline={field.multiline ? true : false}
        error={Boolean(errors[field.key])}
        helperText={errors[field.key]}
        InputProps={{
          endAdornment: field.unit && (
            <InputAdornment position="end">{field.unit}</InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default TypeText;
