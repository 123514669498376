import UpdateIcon from "@mui/icons-material/Update";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ArticleIcon from "@mui/icons-material/Article";

const sidebarMenuItemList = [
  {
    label: "",
    items: [
      {
        label: "Recent Projects",
        target: "_self",
        to: "/projects",
        icon: UpdateIcon,
      },
    ],
  },
  {
    label: "Learning",
    items: [
      {
        label: "Video Tutorials",
        target: "_blank",
        to: "https://ziggurat.ai",
        icon: YouTubeIcon,
      },
      {
        label: "Blog Articles",
        target: "_blank",
        to: "https://ziggurat.ai",
        icon: ArticleIcon,
      },
    ],
  },
];

export default sidebarMenuItemList;
