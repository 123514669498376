import { Box, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { DateLabel } from "../../components/label/DateLabel";
import ExoAvatar from "../../components/exo/ExoAvatar";
import { PopSettings } from "../../components/PopSettings";
import DeleteIcon from "@mui/icons-material/Delete";
import { DBRequest } from "../../api/dbRequest";

export const ScenarioGridItem = ({ item, onRemoved }) => {
  const { t } = useTranslation();

  const user = item.user;

  const actions = [
    { label: t("delete"), onClick: handleDelete, icon: <DeleteIcon /> },
  ];
  function handleDelete() {
    DBRequest({
      path: `scenarios/${item.id}`,
      method: "delete",
      onResponse: () => onRemoved(item.id),
    });
  }

  return (
    <NavLink to={`/scenarios/${item.id}`}>
      <Paper
        className="cursor-pointer w-full p-3 hover:scale-105 relative"
        sx={{ transition: "transform 0.5s ease" }}
      >
        <PopSettings actions={actions} className="absolute right-2 top-2" />
        <img src="/assets/svg/design-view.svg" className="mx-auto w-14 mb-2" />
        <Typography variant="h4" textAlign="center">
          {item.name}
        </Typography>
        <Box className="flex-col justify-between gap-2">
          <Typography
            variant="h5"
            className="flex justify-start items-center gap-2"
          >
            <ExoAvatar
              size="40px"
              picture={user.profile_picture_url}
              name={`${user.lastName} ${user.firstName}`}
            />
            {`${user.lastName} ${user.firstName}`}
          </Typography>
          <Typography variant="h5">
            {t("Last Change") + ": "}
            {item.modified_at && <DateLabel date={item.modified_at} />}
          </Typography>
        </Box>
      </Paper>
    </NavLink>
  );
};
