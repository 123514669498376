import { useTheme, Avatar, Box } from "@mui/material";
import { tokens } from "../../theme/theme";

const ExoAvatar = ({
  onClick,
  className = "",
  id = "",
  sx = {},
  name = "",
  picture,
  color,
  size = "64px",
  type = "contact",
  icon = null,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  if (!picture) {
    type = "contact";
  }

  return (
    <Avatar
      onClick={onClick ? onClick : () => {}}
      id={id}
      src={picture}
      alt="ExoAvatar"
      className={className}
      sx={{
        ...sx,
        cursor: onClick ? "pointer" : "default",
        borderRadius: type === "company" ? "0px" : "50%",
        width: size,
        height: size,
        bgcolor: picture ? "transparent" : color ? color : colors.contrast[400],
        "& img": {
          objectFit: type === "company" ? "contain" : "cover",
        },
      }}
    >
      <Box
        className="h-full w-full flex justify-center items-center"
        sx={{
          fontSize: "calc(" + size + " / 2.5)",
          color: colors.contrast[700],
        }}
      >
        {icon ? icon : getInitials(name)}
      </Box>
    </Avatar>
  );
};

// FUNCTIONS
function getInitials(name) {
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

  let initials = [...name.matchAll(rgx)] || [];

  initials = (
    (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
  ).toUpperCase();

  return initials;
}

export default ExoAvatar;
