import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { PropLayout } from "../PropLayout";

export const PropTypeCheckbox = ({
  label,
  value,
  onChange,
  propKey,
  disabled,
}) => {
  function handleChange(e) {
    onChange(e.target.checked, propKey);
  }
  return (
    <FormControlLabel
      label={label}
      control={
        <Checkbox
          disabled={disabled}
          onChange={handleChange}
          color="secondary"
          checked={value}
        />
      }
    />
  );
};
