function degreesToRadians(degrees) {
  return degrees * (Math.PI / 180);
}

export function calculatePolygonArea(coordinates) {
  const earthRadius = 6371000; // Earth's radius in meters

  // Convert latitude and longitude coordinates to meters
  const coordinatesInMeters = coordinates.map(([lat, lon]) => {
    const x =
      ((lon * Math.PI) / 180) * earthRadius * Math.cos((lat * Math.PI) / 180);
    const y = ((lat * Math.PI) / 180) * earthRadius;
    return [x, y];
  });
  let area = 0;

  // Calculate the Shoelace formula
  for (let i = 0; i < coordinatesInMeters.length; i++) {
    const j = (i + 1) % coordinatesInMeters.length;
    area += coordinatesInMeters[i][0] * coordinatesInMeters[j][1];
    area -= coordinatesInMeters[j][0] * coordinatesInMeters[i][1];
  }

  area = Math.abs(area / 2);

  return area;
}
