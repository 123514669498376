import { Box, FormControl, Input, InputAdornment } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { numberToString, stringToNumber } from "../../../numberConverter";

const EntryEditable = ({ col, items, index, onChange, onFocus = () => {} }) => {
  const textFieldRef = useRef(null);

  const [value, setValue] = useState("");

  useEffect(() => {
    if (!value)
      setValue(
        col.number
          ? numberToString(items[index][col.key], col.decimalPlaces)
          : items[index][col.key]
      );
  }, [items[index][col.key]]);

  function handleFocus() {
    if (parseFloat(value) == 0) setValue("");
    onFocus(textFieldRef);
  }
  function handleBlur(e) {
    onChange(e);
    setValue(
      numberToString(
        stringToNumber(e.target.value, col.decimalPlaces),
        col.decimalPlaces
      )
    );
  }

  function handleChange(e) {
    onChange(e);
    setValue(e.target.value);
  }

  return (
    <Box className="flex flex-col">
      <Box className="w-full">
        <FormControl fullWidth sx={{ m: 0 }}>
          <Input
            sx={{ input: { textAlign: col.align } }}
            ref={textFieldRef}
            onFocus={handleFocus}
            onBlur={col.number ? handleBlur : () => {}}
            size="small"
            className={col.className}
            id={"pos-input-" + col.key + "-" + index}
            value={value}
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="end">{col.unit}</InputAdornment>
            }
          />
        </FormControl>
      </Box>
    </Box>
  );
};

export default EntryEditable;
