import { createElement, useEffect, useState } from "react";
import { typeMap } from "../FormFields";
import TypeCustomSelect from "./TypeCustomSelect";
import { Box } from "@mui/material";

const TypeSelectNFields = ({
  field,
  dataCollection,
  errors,
  updateValidationOnChange,
  startDataCollection,
}) => {
  return (
    <Box className="grid grid-cols-1 gap-2">
      <TypeCustomSelect
        field={field}
        dataCollection={dataCollection}
        errors={errors}
        updateValidationOnChange={updateValidationOnChange}
        startDataCollection={startDataCollection}
      />
      {dataCollection[field.key] &&
        field[dataCollection[field.key]] &&
        field[dataCollection[field.key]].map((newField) =>
          createElement(typeMap[newField.type], {
            field: newField,
            dataCollection,
            errors,
            updateValidationOnChange,
          })
        )}
    </Box>
  );
};

export default TypeSelectNFields;
