import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme/theme";

export const Loader = ({ text, variant = "h3" }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box
      className="flex flex-col gap-2 justify-center items-center"
      sx={{
        "& .loader": {
          width: "48px",
          height: "48px",
          border: `3px solid ${colors.primary[500]}`,
          borderRadius: "50%",
          display: "inline-block",
          position: "relative",
          animation: "rotation 1s linear infinite",
        },
        "& .loader::after": {
          content: "''",
          boxSizing: "border-box",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          border: "3px solid",
          borderColor: `${colors.secondary[500]} transparent`,
        },

        "@keyframes rotation": {
          "0%": {
            transform: "rotate(0deg)",
          },
          "100%": {
            transform: "rotate(360deg)",
          },
        },
      }}
    >
      <span className="loader"></span>
      {text && <Typography variant={variant}>{text}</Typography>}
    </Box>
  );
};

export default Loader;
