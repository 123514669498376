import { useEffect, useState } from "react";
import ExoForm from "../../exo/ExoForm";
import { filterElements } from "./filterElements";
import { Box, IconButton } from "@mui/material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

export const MapFilter = ({ setFilter, filter }) => {
  const [fields, setFields] = useState([]);
  useEffect(() => {
    if (fields.length === 0) setFields(generateFields());
  }, []);

  function generateFields() {
    var newFields = [];
    newFields.push({ type: "label", label: "Asset Types" });
    filterElements.mapElements.filter.forEach((filter) => {
      newFields.push({ ...filter });
    });
    newFields.push({
      type: "divider",
    });
    newFields.push({ type: "label", label: "Asset Properties" });
    return newFields;
  }

  function handleSubmit(data) {
    setFilter(data);
  }
  return (
    <Box className="relative" sx={{ minWidth: "250px" }}>
      <IconButton
        className="top-0 right-0"
        onClick={() => setFilter(null)}
        sx={{ position: "absolute" }}
      >
        <FilterAltOffIcon />
      </IconButton>
      <ExoForm
        noDifferences
        startDataCollection={filter}
        className="gap-2"
        fields={fields}
        onSubmit={handleSubmit}
      />
    </Box>
  );
};
