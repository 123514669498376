import ToolButton from "./ToolButton";

const ClearTool = ({
  mapElements,
  selectedElement,
  active,
  onClick,
  setMapElements,
  setProjectAreas,
  onAlert,
  open,
  currentMode,
  onlyProjectAreas,
}) => {
  const toolElementMap = {
    nodeTool: "node",
    edgeTool: "edge",
    areaTool: "area",
  };

  function handleClear(e) {
    if (onlyProjectAreas) {
      setProjectAreas([]);
    } else {
      setMapElements([]);
      if (currentMode === "projectAreaTool") {
        setProjectAreas([]);
      }
    }

    onClick();
  }
  return (
    <ToolButton
      src={"/assets/svg/tools/clear.svg"}
      label="Clear"
      onClick={handleClear}
      active={active}
      open={open}
      variant="warning"
    />
  );
};
export default ClearTool;
