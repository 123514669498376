import { useState } from "react";
import { MapButton } from "./MapButton";
import SatelliteAltIcon from "@mui/icons-material/SatelliteAlt";
import Typography from "@mui/material/Typography";
import { Box, Paper } from "@mui/material";
import { mapTypes } from "./mapTypes";
import { TileLayer } from "react-leaflet";
export const MapType = ({ preview }) => {
  const [currentType, setCurrentType] = useState(mapTypes[0]);

  return (
    <>
      {(!preview || preview.mapType) && (
        <MapButton
          Icon={SatelliteAltIcon}
          popover={
            <MapTypePopover
              onSelect={(type) => setCurrentType(type)}
              currentType={currentType}
            />
          }
        />
      )}

      <TileLayer url={currentType.url} />
    </>
  );
};

const MapTypePopover = ({ onSelect, currentType }) => {
  return (
    <Box className="flex gap-2 ">
      {mapTypes.map((type) => (
        <MapTypeItem
          type={type}
          onClick={() => onSelect(type)}
          active={type.key === currentType.key}
        />
      ))}
    </Box>
  );
};

const MapTypeItem = ({ type, onClick, active }) => {
  return (
    <Paper
      onClick={onClick}
      className="cursor-pointer"
      elevation={active ? 2 : 0}
    >
      <img className=" w-20 h-20 rounded-t-md" src={type.img} />
      <Typography textAlign="center">{type.label}</Typography>
    </Paper>
  );
};
