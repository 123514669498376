import { Box, Dialog } from "@mui/material";
import ExoForm from "../../components/exo/ExoForm";
import { useTranslation } from "react-i18next";
import ExoStepper from "../../components/exo/ExoStepper";
import StepCustomStartUp from "../../components/exo/steps/StepCustomStartUp";
import { DBRequest } from "../../api/dbRequest";
import * as Yup from "yup";

const GlobalSettings = ({
  open,
  onClose,
  onUpdate,
  startData,
  scenario = {},
}) => {
  const { t } = useTranslation();
  const scenarioDefaultsFields = [
    {
      label: t("Units"),
      key: "units",
      type: "select",
      options: [{ label: "Si (kg, m)", value: "SI" }],
    },
    {
      label: t("Auto divide large pipes"),
      key: "auto_divide_large_pipes",
      type: "checkNFields",
      fields: [
        {
          label: t("Maximum pipe length"),
          key: "max_pipe_length",
          type: "number",
        },
      ],
    },
  ];
  const scenarioValidationSchema = Yup.object().shape({
    units: Yup.string().required(t("Is required")),
    auto_divide_large_pipes: Yup.boolean(),
    max_pipe_length: Yup.number().when("auto_divide_large_pipes", {
      is: true,
      then: (schema) => schema.required("Is required"),
      otherwise: (schema) => schema.optional(),
    }),
  });

  const wasteWaterAccumulationFields = [
    {
      label: t("Water Consumption"),
      key: "water_consumption",
      type: "number",
      unit: "L/(inh*day)",
    },
    {
      label: t("Peak Factor"),
      key: "peak_factor",
      type: "number",
    },
    {
      label: t("Wastewater Return Factor"),
      key: "wastewater_return_factor",
      type: "number",
    },
    {
      label: t("Infiltration"),
      key: "infiltration",
      type: "number",
      unit: "L/km",
    },
    {
      label: t("Population/Population Density"),
      key: "population_type",
      type: "selectNFields",
      options: [
        {
          label: t("Population Density"),
          value: "populationDensity",
        },
        {
          label: t("Population"),
          value: "population",
        },
      ],
      populationDensity: [
        {
          label: t("First Year Population Density (Population/km²)"),
          key: "first_year_population_density",
          type: "number",
          unit: "Population/km²",
        },
        {
          label: t("Final Year Population Density"),
          key: "final_year_population_density",
          type: "number",
          unit: "Population/km²",
        },
      ],
      population: [
        {
          label: t("First Year Population"),
          key: "first_year_population",
          type: "number",
        },
        {
          label: t("Final Year Population"),
          key: "final_year_population",
          type: "number",
        },
      ],
    },
  ];
  const wasteWaterAccumulationValidationSchema = Yup.object().shape({
    water_consumption: Yup.number().required(t("Is required")),
    peak_factor: Yup.number().required(t("Is required")),
    wastewater_return_factor: Yup.number().required(t("Is required")),
    infiltration: Yup.number().required(t("Is required")),
    population_type: Yup.string().required("Is required"),
    first_year_population_density: Yup.mixed().when("population_type", {
      is: "populationDensity",
      then: (schema) => schema.required("Is required"),
      otherwise: (schema) => schema.nullable().optional(),
    }),
    final_year_population_density: Yup.mixed().when("population_type", {
      is: "populationDensity",
      then: (schema) => schema.required("Is required"),
      otherwise: (schema) => schema.nullable().optional(),
    }),
    first_year_population: Yup.mixed().when("population_type", {
      is: "population",
      then: (schema) => schema.required("Is required"),
      otherwise: (schema) => schema.nullable().optional(),
    }),
    final_year_population: Yup.mixed().when("population_type", {
      is: "population",
      then: (schema) => schema.required("Is required"),
      otherwise: (schema) => schema.nullable().optional(),
    }),
  });

  const designCriteriaFields = [
    {
      label: t("Manning Roughness"),
      key: "manning_roughness",
      type: "number",
    },
    {
      label: t("Minimum cover"),
      key: "minimum_cover",
      type: "number",
      unit: "m",
    },
    {
      label: t("Maximum Velocity"),
      key: "maximum_velocity",
      type: "number",
      unit: "m/s",
    },
    {
      label: t("Maximum Slope"),
      key: "maximum_slope",
      type: "number",
    },
    {
      label: t("Maximal buried depth"),
      key: "maximal_buried_depth",
      type: "number",
      unit: "m",
    },
    {
      label: t("Use minimum shear stress"),
      key: "use_minimum_shear_stress",
      type: "checkNFields",
      fields: [
        {
          label: t("Minimum shear stress"),
          key: "minimum_shear_stress",
          type: "number",
        },
      ],
    },
  ];
  const designCriteriaValidationSchema = Yup.object().shape({
    use_minimum_shear_stress: Yup.boolean(),
    minimum_shear_stress: Yup.number().when("use_minimum_shear_stress", {
      is: true,
      then: (schema) => schema.required("Is required"),
      otherwise: (schema) => schema.nullable().optional(),
    }),
    manning_roughness: Yup.number().required(t("Is required")),
    minimum_cover: Yup.number().required(t("Is required")),
    maximal_buried_depth: Yup.number().required(t("Is required")),
    maximum_velocity: Yup.number().required(t("Is required")),
    maximum_slope: Yup.number().required(t("Is required")),
  });

  const diameterFields = [
    {
      label: t("Available sewer diameters"),
      key: "available_sewer_diameters",
      type: "diameterTable",
    },
  ];
  const diameterValidationSchema = Yup.object().shape({
    available_sewer_diameters: Yup.array().of(
      Yup.object().shape({
        minimum_velocity: Yup.number().required(t("Is required")),
        //maximum_velocity: Yup.number().required(t("Is required")),
        minimum_slope: Yup.number().required(t("Is required")),
        //maximum_slope: Yup.number().required(t("Is required")),
        diameter: Yup.number().required(t("Is required")),
        maximal_water_depth: Yup.number().required(t("Is required")),
      })
    ),
  });

  const pumpsFields = [
    {
      label: t("Use lift stations where excavation depth > max. depth"),
      key: "use_lift_stations_where_excavation_depth",
      type: "checkbox",
    },
  ];
  const pumpsValidationSchema = Yup.object().shape({
    use_lift_stations_where_excavation_depth: Yup.boolean().required(
      t("Is required")
    ),
  });

  const costFunctionFields = [
    {
      label: t("Cost Function"),
      key: "cost_function_id",
      type: "costFunction",
    },
  ];
  const costFunctionValidationSchema = Yup.object().shape({
    cost_function_id: Yup.number().required(t("Is required")),
  });

  const steps = [
    {
      label: t("Scenario Defaults"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepCustomStartUp
          fields={scenarioDefaultsFields}
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          validationSchema={scenarioValidationSchema}
        />
      ),
    },
    {
      label: t("Waste Water Accumulation"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepCustomStartUp
          fields={wasteWaterAccumulationFields}
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          validationSchema={wasteWaterAccumulationValidationSchema}
        />
      ),
    },
    {
      label: t("Design Criteria"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepCustomStartUp
          fields={designCriteriaFields}
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          validationSchema={designCriteriaValidationSchema}
        />
      ),
    },
    {
      label: t("Diameter"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepCustomStartUp
          fields={diameterFields}
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          validationSchema={diameterValidationSchema}
        />
      ),
    },
    {
      label: t("Pumps"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepCustomStartUp
          fields={pumpsFields}
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          validationSchema={pumpsValidationSchema}
        />
      ),
    },
    {
      label: t("Cost Function"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepCustomStartUp
          fields={costFunctionFields}
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          validationSchema={costFunctionValidationSchema}
        />
      ),
    },
  ];

  function generateOptions(start, end, stepsize) {
    var newOptions = [];
    for (var i = start; i <= end; i += stepsize) {
      const number = Math.round(i * 100) / 100;
      newOptions.push({
        label: number,
        value: number,
      });
    }
    return newOptions;
  }

  const finalStep = {
    operation: ({
      dataCollection,
      handleResponse,
      setIsLoading,
      handleError,
    }) => {
      setIsLoading(true);
      DBRequest({
        path: `scenario_settings/${scenario.id}`,
        method: "patch",
        data: dataCollection,
        onResponse: handleUpdateResponse,
        onLoading: setIsLoading,
        onError: handleError,
      });
    },
    content: (response) => <Box>Saved</Box>,
  };

  function handleUpdateResponse(data) {
    onUpdate({ ...scenario, globalSettings: data.globalSettings });
  }

  const defaultData = {
    units: "SI",
    auto_divide_large_pipes: false,
    max_pipe_length: 100,
    water_consumption: 120,
    peak_factor: 1.8,
    wastewater_return_factor: 0.8,
    infiltration: 0.1,
    population_type: "populationDensity",
    first_year_population_density: 4000,
    final_year_population_density: 5000,
    first_year_population: null,
    final_year_population: null,
    minimum_velocity: 0.6,
    maximum_velocity: 5,
    minimum_slope: 0.33,
    maximum_slope: 10,
    use_minimum_shear_stress: false,
    minimum_shear_stress: 1,
    manning_roughness: 0.013,
    maximal_water_depth: 0.8,
    minimum_cover: 1,
    maximal_buried_depth: 5,
    available_sewer_diameters: null, // make sure the default values are loaded correctly
    use_lift_stations_where_excavation_depth: false,
    max_number_of_pump_stations: null,
    cost_function_id: null,
    max_outfall: 1,
  };

  return (
    <Dialog
      open={Boolean(open)}
      sx={{
        "& .MuiDialog-paper": {
          maxHeight: "90vh",
          height: "800px",
          margin: 0,
          width: "1200px",
          maxWidth: "100vw!important",
          overflowX: "auto",
        },
      }}
    >
      <ExoStepper
        label={t("Global Settings")}
        onClose={onClose}
        data={steps}
        finalStep={finalStep}
        startDataCollection={startData || defaultData}
      />
    </Dialog>
  );
};

export default GlobalSettings;
