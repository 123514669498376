//thats the main colors of the app in Center of shades

const defaultColors = {
  primary: "#343434",
  secondary: "#6EB96E",
  contrast: "#c9c9c9",
  greenAccent: "#6EB96E",
  redAccent: "#CE2121",
  blueAccent: "#2392b8",
};

export default defaultColors;
