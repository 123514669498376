import { useEffect, useRef, useState } from "react";
import { CircleMarker, useMap } from "react-leaflet";
import useMapElementStyles from "../useMapElementStyles";
import { updateMapElementById } from "../helperFunctions/updateMapElementById";

const ProjectAreaNode = ({
  polygon,
  element,
  currentMode,
  onSelect,
  selectedElement,
  projectAreas,
  setProjectAreas,
  index,
}) => {
  const mapStyles = useMapElementStyles();

  const [position, setPosition] = useState(element.position);
  const [key, setKey] = useState(0);
  const nodeRef = useRef(null);
  const map = useMap(); // Access the Leaflet map instance
  const [draggable, setDraggable] = useState(false);

  const color =
    selectedElement.id === element.id
      ? mapStyles.projectAreaNode.colors.selected
      : mapStyles.projectAreaNode.colors[currentMode] ||
        mapStyles.projectAreaNode.colors.default;

  useEffect(() => {
    setKey(key + 1);

    // check if its draggable
    setDraggable(currentMode === "projectAreaTool");
  }, [currentMode, projectAreas, selectedElement]);

  function updateProjectAreaNode() {
    if (!nodeRef.current) return;
    const position = nodeRef.current.getLatLng();

    var newPositions = polygon.positions;
    newPositions[index] = [position.lat, position.lng];

    const newPolygon = { ...polygon, positions: newPositions };

    setProjectAreas(updateMapElementById(projectAreas, newPolygon));
  }

  function handleMouseDown(e) {
    // Disable text selection on the map container or any specific element
    const mapContainer = map.getContainer();
    mapContainer.style.userSelect = "none";
    mapContainer.style.webkitUserSelect = "none"; // For Chrome, Safari, and Opera
    mapContainer.style.MozUserSelect = "none"; // For Firefox
    mapContainer.style.msUserSelect = "none";
    if (!draggable) return;
    map.dragging.disable();
    map.on("mousemove", function (e) {
      setPosition(e.latlng);
      updateProjectAreaNode();
    });
  }

  function handleMouseUp() {
    // Enable text selection on the map container or the specific element after mouse up
    const mapContainer = map.getContainer();
    mapContainer.style.userSelect = "";
    mapContainer.style.webkitUserSelect = "";
    mapContainer.style.MozUserSelect = "";
    mapContainer.style.msUserSelect = "";
    if (!draggable) return;
    map.removeEventListener("mousemove");
    map.dragging.enable();
    updateProjectAreaNode();
  }

  const handleMouseOver = () => {
    if (nodeRef.current) {
      nodeRef.current.setStyle({
        color:
          mapStyles.projectAreaNode.colors.hover ||
          mapStyles.projectAreaNode.colors.default,
      }); // Change color on hover
    }
  };

  const handleMouseOut = () => {
    if (nodeRef.current) {
      nodeRef.current.setStyle({ color: color }); // Reset color on mouseout
    }
  };

  const eventHandlers = {
    mousedown: (e) => {
      handleMouseDown(e);
      onSelect("ProjectAreaNode", element);
    },
    mouseover: handleMouseOver,
    mouseout: handleMouseOut,
    mouseup: () => handleMouseUp(draggable),
  };

  const radius = draggable
    ? mapStyles.projectAreaNode.size.draggable
    : mapStyles.projectAreaNode.size[currentMode] ||
      mapStyles.projectAreaNode.size.default;

  return (
    <CircleMarker
      key={key}
      ref={nodeRef}
      center={position}
      eventHandlers={eventHandlers}
      radius={radius}
      color={polygon.color || color}
    />
  );
};

export default ProjectAreaNode;
