import { Box, useTheme } from "@mui/material";
import { tokens } from "../theme/theme";
import Loader from "../components/Loader";

const AuthScreen = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box
      className="w-full h-full absolute top-0 left-0 z-50 flex justify-center items-center flex-col"
      sx={{
        backgroundColor: colors.contrast[100],
      }}
    >
      <Loader text="Authenticate" />
    </Box>
  );
};

export default AuthScreen;
