import { Box } from "@mui/material";

const EntryConst = ({ unit, value, className }) => {
  return (
    <Box className={"flex items-center " + className} sx={{ height: "26px" }}>
      {value} {unit}
    </Box>
  );
};

export default EntryConst;
