import axios from "axios";

const defaultBaseUrl = "https://demo-api.ziggurat.ai/";

export async function DBRequest({
  baseUrl = defaultBaseUrl,
  path,
  data = {},
  method = "get",
  onResponse = () => {},
  onError = () => {},
  onLoading = () => {},
}) {
  onLoading(true);
  const token = localStorage.getItem("jwtToken");
  await axios({
    method: method,
    url: baseUrl + path,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      onResponse(response.data);
      onLoading(false);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      onError(error);
      onLoading(false);
    });
}
