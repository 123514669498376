export const validateDataCollection = async (
  validationSchema,
  dataCollection,
  onValidation,
  debug
) => {
  if (!validationSchema) {
    // no schema => isValid
    onValidation(true);
    return;
  }
  try {
    await validationSchema.validate(dataCollection);
    // validation passed for the specific field
    onValidation(true);
  } catch (err) {
    // validation failed for the specific field
    if (debug) console.log("NOT VALID", err);
    onValidation(false);
  }
};
