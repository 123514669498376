import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import FormHelperText from "../FormHelperText";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";

const moment = require("moment");

const TypeDateTimeRange = ({
  field,
  dataCollection,
  errors = {},
  updateValidationOnChange,
}) => {
  const [startDateTime, setStartDateTime] = useState(null);
  const [endDateTime, setEndDateTime] = useState(null);

  const format = "DD-MM-YYYY HH:mm";

  useEffect(() => {
    // init moment date objects
    if (dataCollection[field.key]) {
      setStartDateTime(moment(dataCollection[field.key].dateTimeStart, format));
      setEndDateTime(moment(dataCollection[field.key].dateTimeEnd, format));
    }
  }, []);

  function handleChangeStart(value) {
    setStartDateTime(value);
    updateValidationOnChange([field.key + "Start"], value.format(format));
  }
  function handleChangeEnd(value) {
    setEndDateTime(value);
    updateValidationOnChange([field.key + "End"], value.format(format));
  }
  return (
    <Box>
      <Box className="flex flex-row gap-2 w-full items-center">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateTimePicker
            className="w-full"
            onChange={handleChangeStart}
            value={startDateTime}
            format={format}
            label="Start Date Time"
            ampm={false}
          />
          -
          <DateTimePicker
            className="w-full"
            onChange={handleChangeEnd}
            value={endDateTime}
            format={format}
            label="End Date Time"
            ampm={false}
          />
        </LocalizationProvider>
      </Box>
      <FormHelperText
        error={Boolean(errors[field.key])}
        helperText={errors[field.key]}
      />
    </Box>
  );
};

export default TypeDateTimeRange;
