import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";
import calcColorTheme from "./CalcColorTheme";
import Cookies from "universal-cookie";
import defaultColors from "./defaultColors";
import { themeSettings } from "./muiTheme";

// color design tokens
export const tokens = (mode, customColorTheme) => {
  const fixedColors = {
    glass: mode === "dark" ? "rgba(0, 0, 0, 0.4)" : "rgba(250, 250, 250, 0.4)",
    card: mode === "dark" ? "rgba(50, 50, 50)" : "rgb(240, 240, 240)",
    money:
      mode === "dark"
        ? {
            positive: "#5bc989",
            negative: "#b55555",
            neutral: "#D3D3D3",
          }
        : {
            positive: "#24854c",
            negative: "#852424",
            neutral: "#D3D3D3",
          },
  };

  return { ...fixedColors, ...customColorTheme };
};

//context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const cookies = new Cookies();

  // color mode
  const [mode, setMode] = useState("light");
  /*
  const [mode, setMode] = useState(
    cookies.get("colorMode") ? cookies.get("colorMode") : "light"
  );
  */
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prev) => {
          const newMode = prev === "light" ? "dark" : "light";
          updateTheme(newMode);
          return newMode;
        });
      },
    }),
    []
  );

  // color theme
  var storedColors = defaultColors;
  /*
  if (cookies.get("colorTheme") !== "EMPTY") {
    storedColors = cookies.get("colorTheme");
  }
*/
  const [colorTheme, setColorTheme] = useState(
    calcColorTheme(storedColors, mode)
  );

  function updateTheme(newMode) {
    setColorTheme(calcColorTheme(storedColors, newMode));
  }

  // create theme
  const theme = useMemo(
    () => createTheme(themeSettings(mode, colorTheme)),
    [mode]
  );

  // change Theme Settings

  return [theme, colorMode];
};
