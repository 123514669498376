import { useEffect, useRef, useState } from "react";
import { CircleMarker, useMap } from "react-leaflet";
import useMapElementStyles from "../useMapElementStyles";
import { updateMapElementById } from "../helperFunctions/updateMapElementById";

const AreaNode = ({
  polygon,
  element,
  currentMode,
  onSelect,
  selectedElement,
  mapElements,
  setMapElements,
  index,
  onProjectAreaCheck,
  draggable,
}) => {
  const mapStyles = useMapElementStyles();

  const [position, setPosition] = useState(element.position);
  const [key, setKey] = useState(0);
  const nodeRef = useRef(null);
  const map = useMap(); // Access the Leaflet map instance

  const color =
    selectedElement.id === element.id
      ? mapStyles.areaNode.colors.selected
      : mapStyles.areaNode.colors[currentMode] ||
        mapStyles.areaNode.colors.default;

  useEffect(() => {
    setKey(key + 1);
  }, [currentMode, mapElements, selectedElement]);

  function updateAreaNode() {
    if (!nodeRef.current) return;
    const position = nodeRef.current.getLatLng();

    var newPositions = polygon.positions;
    newPositions[index] = [position.lat, position.lng];

    const newPolygon = { ...polygon, positions: newPositions };

    setMapElements(updateMapElementById(mapElements, newPolygon));
  }

  function handleMouseDown(e) {
    // Disable text selection on the map container or any specific element
    const mapContainer = map.getContainer();
    mapContainer.style.userSelect = "none";
    mapContainer.style.webkitUserSelect = "none"; // For Chrome, Safari, and Opera
    mapContainer.style.MozUserSelect = "none"; // For Firefox
    mapContainer.style.msUserSelect = "none";
    if (!draggable) return;
    map.dragging.disable();
    map.on("mousemove", function (e) {
      const { lat, lng } = e.latlng;
      const newPosition = [lat, lng];
      if (onProjectAreaCheck(newPosition)) {
        setPosition(newPosition);
        updateAreaNode();
      }
    });
  }

  function handleMouseUp() {
    // Enable text selection on the map container or the specific element after mouse up
    const mapContainer = map.getContainer();
    mapContainer.style.userSelect = "";
    mapContainer.style.webkitUserSelect = "";
    mapContainer.style.MozUserSelect = "";
    mapContainer.style.msUserSelect = "";
    if (!draggable) return;
    map.removeEventListener("mousemove");
    map.dragging.enable();
    updateAreaNode();
  }

  const handleMouseOver = () => {
    if (nodeRef.current) {
      nodeRef.current.setStyle({
        color:
          mapStyles.areaNode.colors.hover || mapStyles.areaNode.colors.default,
      }); // Change color on hover
    }
  };

  const handleMouseOut = () => {
    if (nodeRef.current) {
      nodeRef.current.setStyle({ color: color }); // Reset color on mouseout
    }
  };

  const eventHandlers = {
    mousedown: (e) => {
      handleMouseDown(e);
      onSelect("AreaNode", element);
    },
    mouseover: handleMouseOver,
    mouseout: handleMouseOut,
    mouseup: () => handleMouseUp(draggable),
  };

  const radius = draggable
    ? mapStyles.areaNode.size.draggable
    : mapStyles.areaNode.size[currentMode] || mapStyles.areaNode.size.default;

  return (
    <CircleMarker
      key={key}
      ref={nodeRef}
      center={position}
      eventHandlers={eventHandlers}
      radius={radius}
      color={polygon.color || color}
    />
  );
};

export default AreaNode;
