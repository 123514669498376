import { Box, IconButton, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";

const Controls = ({ cols, onNewPosition, hideNewPosition }) => {
  const { t } = useTranslation();
  return (
    <tr>
      <td colSpan={cols.length}>
        <Box className="w-full flex flex-row justify-center items-center">
          {!hideNewPosition && (
            <Tooltip title={t("Add New Diameter")} arrow>
              <IconButton onClick={onNewPosition}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </td>
    </tr>
  );
};

export default Controls;
