import { Box, useTheme, Typography } from "@mui/material";
import React from "react";
import { tokens } from "../../../../theme/theme";

export const FieldContainer = ({
  children,
  label = "LOL",
  onClick,
  className,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box
      onClick={onClick}
      className="MuiFormControl-root MuiTextField-root relative"
      sx={{
        borderRadius: "3px",
        backgroundColor: colors.contrast[300],
        ":hover": {
          cursor: "pointer",
        },
      }}
    >
      <Box
        sx={{
          fontWeight: 400,
          fontSize: "0.8571428571428571rem",
          lineHeight: "1.4375em",
          transformOrigin: "top left",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "calc(133% - 24px)",
          position: "absolute",
          transform: children
            ? "translate(12px, 7px) scale(0.75)"
            : "translate(12px, 16px) scale(1)",
          left: 0,
          top: 0,
          transition:
            "color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
        }}
      >
        {label}
      </Box>
      <Box
        sx={{ padding: "25px 12px 8px 12px", minHeight: `${19.7 + 25 + 8}px` }}
        className={className}
      >
        {children}
      </Box>
    </Box>
  );
};
