import {
  Box,
  Button,
  Chip,
  IconButton,
  Snackbar,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { PropLayout } from "../PropLayout";
import { Fragment, useEffect, useState } from "react";
import { FieldContainer } from "./FieldContainer";
import { tokens } from "../../../../theme/theme";
import { useTranslation } from "react-i18next";

export const PropTypeNodeSelection = ({
  label,
  value,
  onChange,
  propKey,
  disabled,
  setCurrentMode,
  elementSelection,
  setElementSelection,
  currentMode,
}) => {
  const { t } = useTranslation();

  function handleSelectionMode() {
    setCurrentMode((prev) => (prev === "selection" ? "edit" : "selection"));
  }

  useEffect(() => {
    if (currentMode !== "selection") return;
    var newNodesIdArray = [];
    const selectedNodes = elementSelection.filter((obj) => obj.type === "node");
    selectedNodes.forEach((node) => {
      newNodesIdArray.push(node.id);
    });
    onChange(newNodesIdArray, propKey);
  }, [elementSelection]);

  function onRemoveElement(id) {
    const selectedNodes = elementSelection.filter(
      (obj) => obj.type === "node" && obj.id !== id
    );
    var newNodesIdArray = [];

    selectedNodes.forEach((node) => {
      newNodesIdArray.push(node.id);
    });

    onChange(newNodesIdArray, propKey);
    setElementSelection(selectedNodes);
  }

  const action = (
    <Button color="secondary" size="small" onClick={handleSelectionMode}>
      {t("FINISHED")}
    </Button>
  );

  return (
    <>
      <FieldContainer
        label={label}
        onClick={handleSelectionMode}
        className="flex gap-1 pb-2 w-full flex-wrap"
      >
        {elementSelection &&
          elementSelection.length > 0 &&
          elementSelection.map((node) => (
            <Chip label={node.name} onDelete={() => onRemoveElement(node.id)} />
          ))}
      </FieldContainer>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={currentMode === "selection"}
        message={`${elementSelection && elementSelection.length} ${t(
          "nodes are selected"
        )}`}
        action={action}
      />
    </>
  );
};
