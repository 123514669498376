import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import ArchitectureRoundedIcon from "@mui/icons-material/ArchitectureRounded";

const topbarItemListRight = [
  {
    label: "Help",
    target: "_blank",
    to: "https://ziggurat.ai",
    icon: QuestionMarkIcon,
  },
];

export default topbarItemListRight;
