import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import FormHelperText from "../FormHelperText";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const TypeCustomSelect = ({
  updateValidationOnChange,
  dataCollection,
  field,
  errors,
  startDataCollection,
}) => {
  const [value, setValue] = useState("");
  const { t } = useTranslation();
  function handleChange(e) {
    updateValidationOnChange(field.key, e.target.value);
    setValue(e.target.value);
  }

  useEffect(() => {
    if (value !== "" || !startDataCollection[field.key]) return;
    setValue(startDataCollection[field.key]);
  }, [startDataCollection]);

  useEffect(() => {
    if (dataCollection[field.key]) setValue(dataCollection[field.key]);
  }, [dataCollection[field.key]]);

  return (
    <FormControl fullWidth variant="filled">
      <InputLabel id="custom-select-label">{field.label}</InputLabel>
      <Select
        labelId="custom-select-label"
        value={value}
        label={field.label}
        onChange={handleChange}
        error={Boolean(errors[field.key])}
      >
        {field.options &&
          field.options.map((option) => (
            <MenuItem value={option.value}>{option.label}</MenuItem>
          ))}
      </Select>
      <FormHelperText
        error={Boolean(errors[field.key])}
        helperText={errors[field.key]}
      />
    </FormControl>
  );
};

export default TypeCustomSelect;
