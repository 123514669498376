export const filterElements = {
  mapElements: {
    label: "Map Elements",
    filter: [
      { label: "Areas", type: "smallCheckbox", key: "area" },
      { label: "Edges", type: "smallCheckbox", key: "edge" },
      { label: "Nodes", type: "smallCheckbox", key: "node" },
    ],
  },
  properties: {
    label: "Properties",
    filter: [
      { label: "Diameter", type: "rangeslider", key: "diameter" },
      { label: "Length", type: "rangeslider", key: "length" },
      { label: "Elevation", type: "rangeslider", key: "elevation" },
      { label: "Materials", type: "materials", key: "material" },
    ],
  },
};
