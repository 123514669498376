import { useEffect, useState } from "react";
import { CircleMarker, Polygon, useMap, useMapEvents } from "react-leaflet";
import useMapElementStyles from "../useMapElementStyles";
import ToolButton from "../tools/ToolButton";
import { v4 as uuidv4 } from "uuid";
import { calculatePolygonArea } from "../helperFunctions/calcPolygonArea";
import { Box, useTheme } from "@mui/material";
import CursorBox from "../CursorBox";
import { tokens } from "../../../theme/theme";
import { useTranslation } from "react-i18next";

export const ProjectAreaTool = ({
  onAlert,
  projectAreas,
  selectedElement,
  active,
  onClick,
  setProjectAreas,
  open,
  maxProjectAreas,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const [positions, setPositions] = useState(null);
  const [cursorPosition, setCursorPosition] = useState(null);
  const mapStyles = useMapElementStyles();

  useEffect(() => {
    setPositions(null);
  }, [active]);

  // CLICK EVENTS
  const handleMapClick = (e) => {
    if (
      e.originalEvent.target.tagName === "BUTTON" ||
      e.originalEvent.target.tagName === "IMG"
    )
      return;
    if (!active) return;

    // check if areas limit is reached
    if (projectAreas.length >= maxProjectAreas)
      return onAlert(
        `The limit of project areas is reached (max ${maxProjectAreas})`,
        "warning"
      );

    // get coordinates
    const lat = e.latlng.lat;
    const lng = e.latlng.lng;

    // add to areaNodes
    const newPositions = positions || [];
    newPositions.push([lat, lng]);
    setPositions(newPositions);
  };

  function handleMouseMove(e) {
    const { lat, lng } = e.latlng;
    const newCursorPosition = [lat, lng];
    setCursorPosition(newCursorPosition);
  }

  function handleMapRightClick() {
    createPolygon();
    setPositions(null);
  }

  function createPolygon() {
    if (positions === null || positions.length < 3) return;
    const newArea = {
      id: uuidv4(),
      positions: positions,
      area: calculatePolygonArea(positions),
    };
    setProjectAreas([...projectAreas, newArea]);
  }
  // Access the Leaflet map instance
  useMapEvents({
    click: handleMapClick,
    mousemove: handleMouseMove,
    contextmenu: (e) => {
      if (!active) return;
      // Prevent the default context menu behavior
      e.originalEvent.preventDefault();
      handleMapRightClick(e);
    },
  });

  function buttonClick(e) {
    if (active && positions) {
      createPolygon();
    }
    onClick(e, "projectAreaTool");
  }

  // NODE
  const nodeRadius =
    mapStyles.projectAreaNode.size["projectAreaTool"] ||
    mapStyles.projectAreaNode.size.default;

  const nodeColor =
    mapStyles.projectAreaNode.colors["projectAreaTool"] ||
    mapStyles.projectAreaNode.colors.default;

  const nodeEventHandlers = {
    mousedown: (e) => {
      // Prevent the default context menu behavior
      e.originalEvent.preventDefault();
    },
  };

  // CALC current Area Size
  const [currentAreaSize, setCurrentAreaSize] = useState(0);
  useEffect(() => {
    if (!cursorPosition || !positions) setCurrentAreaSize(0);
    if (cursorPosition && positions) updateCurrentAreaSize();
  }, [cursorPosition, positions]);
  function updateCurrentAreaSize() {
    setCurrentAreaSize(calculatePolygonArea([...positions, cursorPosition]));
  }

  // CALC total Area Size
  const [totalAreaSize, setTotalAreaSize] = useState(0);
  useEffect(() => {
    updateTotalAreaSize();
  }, [projectAreas]);
  function updateTotalAreaSize() {
    var newArea = 0;
    projectAreas.forEach((area) => {
      newArea += calculatePolygonArea(area.positions);
    });
    setTotalAreaSize(newArea);
  }
  return (
    <>
      <ToolButton
        src={"/assets/svg/tools/projectArea.svg"}
        label="Project Area"
        onClick={buttonClick}
        active={active}
        open={open}
      />
      {active && (
        <Box
          className=" z-50 absolute left-4 bottom-4 backdrop-blur-sm px-2 py-1 rounded-lg"
          sx={{
            bgcolor: colors.contrast[300] + "80",
          }}
        >
          {`${t("Total:")} ${parseFloat(
            (totalAreaSize + currentAreaSize) / 1000000
          ).toFixed(3)} km²`}
        </Box>
      )}
      {active && positions && (
        <>
          <CursorBox>
            {parseFloat(currentAreaSize / 1000000).toFixed(3)} km²
          </CursorBox>
          <Polygon
            key={positions}
            weight={mapStyles.area.size.preview}
            positions={[...(positions || []), cursorPosition || []]}
            color={mapStyles.area.colors.preview}
          />
          {positions.map((position, posIndex) => (
            <CircleMarker
              key={posIndex}
              center={position}
              radius={nodeRadius}
              color={nodeColor}
              eventHandlers={nodeEventHandlers}
            />
          ))}
        </>
      )}
    </>
  );
};
