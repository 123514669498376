import { Polygon, Tooltip } from "react-leaflet";
import useMapElementStyles from "../useMapElementStyles";
import { useEffect, useRef, useState } from "react";
import AreaNode from "./AreaNode";
import { deleteMapElementById } from "../helperFunctions/deleteMapElementById";

export const Area = ({
  element,
  currentMode,
  onSelect,
  selectedElement,
  mapElements,
  setMapElements,
  onProjectAreaCheck,
}) => {
  // styles
  const mapStyles = useMapElementStyles();
  const areaRef = useRef(null);

  const isSelected = selectedElement.id === element.id;

  const weight =
    mapStyles.area.size[currentMode] || mapStyles.area.size.default;

  var color = isSelected
    ? mapStyles.area.colors.selected
    : mapStyles.area.colors.default;
  if (!element.isSaved) color = mapStyles.area.colors.notSaved;
  const dashArray = mapStyles.area.dashArray;
  const fillOpacity =
    mapStyles.area.fillOpacity[currentMode] ||
    mapStyles.area.fillOpacity.default;

  const [isDraggable, setIsDraggable] = useState(false);

  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey(key + 1);
    if (element && element.props && element.props.length) {
      const typeProp = element.props.find((obj) => obj.key === "areaType");
      if (typeProp) {
        setIsDraggable(`${typeProp.value.toLowerCase()}Tool` === currentMode);
      } else {
        setIsDraggable(false);
      }
    }
  }, [isSelected, currentMode, element]);

  const handleMouseOver = () => {
    if (areaRef.current) {
      areaRef.current.setStyle({ color: mapStyles.edge.colors.hover }); // Change color on hover
    }
  };

  const handleMouseOut = () => {
    if (areaRef.current) {
      areaRef.current.setStyle({ color: color }); // Reset color on mouseout
    }
  };

  const eventHandlers = {
    click: () => onSelect(element),
    mouseover: handleMouseOver,
    mouseout: handleMouseOut,
  };

  // shortcuts
  useEffect(() => {
    // Add an event listener for the "keydown" event
    document.addEventListener("keydown", handleDeleteShortcut);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleDeleteShortcut);
    };
  }, []);

  const handleDeleteShortcut = (event) => {
    // Check if the pressed key is the "Entf" (Delete) key
    if (event.key === "Delete") {
      if (!selectedElement.id) return;
      deleteMapElementById(setMapElements, selectedElement.id);
    }
  };

  if (currentMode === "selection" && selectedElement.id !== element.id) return;

  return (
    <>
      <Polygon
        ref={areaRef}
        key={key}
        positions={element.positions}
        fillOpacity={fillOpacity}
        weight={weight}
        color={element.color || color}
        dashArray={dashArray}
        eventHandlers={eventHandlers}
      >
        {element.name && (
          <Tooltip direction="bottom" offset={[0, 0]} opacity={1}>
            {element.name}
          </Tooltip>
        )}
      </Polygon>
      {element.positions.map((position, posIndex) => (
        <AreaNode
          key={`${element.id}-${posIndex}`}
          element={{ position: position, id: element.id }}
          polygon={element}
          currentMode={currentMode}
          mapElements={mapElements}
          setMapElements={setMapElements}
          selectedElement={selectedElement}
          onSelect={onSelect}
          index={posIndex}
          onProjectAreaCheck={onProjectAreaCheck}
          draggable={isDraggable}
        />
      ))}
    </>
  );
};
