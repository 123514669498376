import { Box, Checkbox, useTheme, FormControlLabel } from "@mui/material";

import FormHelperText from "../FormHelperText";
import { tokens } from "../../../theme/theme";

const TypeSmallCheckbox = ({
  field,
  dataCollection,
  errors,
  updateValidationOnChange,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  function handleChange(value) {
    updateValidationOnChange([field.key], value.target.checked);
  }
  return (
    <Box className="flex flex-row items-center justify-start">
      <FormControlLabel
        label={field.label}
        control={
          <Checkbox
            onChange={handleChange}
            color="secondary"
            checked={dataCollection[field.key]}
          />
        }
      />
      <FormHelperText
        error={Boolean(errors[field.key])}
        helperText={errors[field.key]}
      />
    </Box>
  );
};

export default TypeSmallCheckbox;
