import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../theme/theme";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export const CreateProjectAreaCard = ({ setOpenProjectArea }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  return (
    <Box
      className="relative overflow-hidden col-span-1 lg:col-span-2 xl:col-span-3 2xl:col-span-4 rounded-lg flex flex-col justify-center items-center transition-colors cursor-pointer"
      span={4}
      sx={{
        maxHeight: "400px",
        height: "400px",
        bgcolor: colors.card,
        ":hover": {
          bgcolor: colors.contrast[400],
        },
      }}
      onClick={() => setOpenProjectArea(true)}
    >
      <AddCircleOutlineIcon
        sx={{ fontSize: "50px", color: colors.contrast[600] }}
      />
      <Typography
        variant="h2"
        sx={{ color: colors.contrast[600] }}
        textAlign="center"
      >
        {t("Create Project Area")}
      </Typography>
    </Box>
  );
};
