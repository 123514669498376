import { Box, Typography } from "@mui/material";
import { PropField } from "./PropField";
import { PropGroup } from "./PropGroup";

export const PropsPositionField = ({
  value,
  propKey,
  onChange,
  label,
  disabled,
}) => {
  function handleChange(newValue, propKey) {
    var newPosition = value;
    newPosition[propKey] = newValue;
    onChange(newPosition, propKey);
  }
  return (
    <Box className="flex flex-col gap-1">
      {label && (
        <Typography variant="h6" className="pt-2">
          {label}
        </Typography>
      )}
      <PropField
        disabled={disabled}
        label="LAT"
        value={value[0]}
        onChange={handleChange}
        propKey={0}
        type="number"
      />
      <PropField
        disabled={disabled}
        label="LNG"
        value={value[1]}
        onChange={handleChange}
        propKey={1}
        type="number"
      />
    </Box>
  );
};
