import { Backdrop, useTheme } from "@mui/material";
import Loader from "./Loader";
import { useTranslation } from "react-i18next";
import { tokens } from "../theme/theme";

export const LoadingBlur = ({ active, text }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  return (
    <Backdrop
      open={active}
      sx={{ zIndex: 99999999, bgcolor: colors.contrast[200] + "80" }}
      className="flex justify-center items-center backdrop-blur-sm"
    >
      <Loader text={text || t("Loading...")} />
    </Backdrop>
  );
};
