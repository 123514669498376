const findDifferences = (oldObj = {}, newObj) => {
  const differences = {};
  if (Object.keys(oldObj).length === 0) return newObj;
  for (let key in newObj) {
    // always add delivery
    if (key === "delivery") differences[key] = newObj[key];
    if (key === "positions") differences[key] = newObj[key];
    // check if value exists and is differernt
    if (oldObj.hasOwnProperty(key) && newObj.hasOwnProperty(key)) {
      if (oldObj[key] !== newObj[key]) {
        differences[key] = newObj[key];
      }
    }
    if (!oldObj.hasOwnProperty(key) && newObj.hasOwnProperty(key)) {
      differences[key] = newObj[key];
    }
  }

  return differences;
};

export default findDifferences;
