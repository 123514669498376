import { Box } from "@mui/material";
import { PropGroup } from "../propretiesComponents/PropGroup";
import { PropField } from "../propretiesComponents/PropField";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

export const AreaProperties = ({
  element,
  onUpdate,
  onReset,
  disabled,
  errors,
  setCurrentMode,
  elementSelection,
  setElementSelection,
  currentMode,
  mapElements,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    const selection = element.props.find((obj) => obj.key === "nodes");

    if (selection) {
      updateElementSelection(selection.value);
    }
  }, [element.id]);

  function updateElementSelection(nodeIdArray) {
    var newNodeSelection = [];
    var nodesFound = [];
    nodeIdArray.forEach((nodeId) => {
      const node = mapElements.find((mapElement) => mapElement.id === nodeId);
      if (node) {
        nodesFound.push(node.id);
        newNodeSelection.push(node);
      }
    });
    handlePropChange(nodesFound, "nodes", "props");
    setElementSelection(newNodeSelection);
  }

  function handlePropChange(newValue, propKey, catKey) {
    var newProps = [];
    element[catKey].forEach((prop) => {
      newProps.push({
        ...prop,
        value: prop.key === propKey ? newValue : prop.value,
      });
    });
    onUpdate(newProps, catKey);
  }

  return (
    <Box className="flex flex-col gap-2">
      <PropGroup label="Base" borderWidth={0}>
        <PropField
          label="ID"
          value={element.id}
          onChange={onUpdate}
          propKey="id"
          type="display"
          disabled={disabled}
          errors={errors}
        />
        <PropField
          label="NAME"
          value={element.name}
          onChange={onUpdate}
          propKey="name"
          type="text"
          disabled={disabled}
          errors={errors}
        />
      </PropGroup>
      <PropField
        disabled={disabled}
        value={element.positions}
        label={"Positions"}
        type={"positions"}
        propKey={"positions"}
        onChange={onUpdate}
        errors={errors}
      />
      <PropGroup label={t("Properties")}>
        {element.props &&
          element.props.map((prop, index) => (
            <PropField
              disabled={disabled}
              value={prop.value}
              label={prop.label}
              type={prop.type}
              propKey={prop.key}
              onChange={(value, key) => handlePropChange(value, key, "props")}
              key={prop.key}
              errors={errors}
              setCurrentMode={setCurrentMode}
              elementSelection={elementSelection || []}
              setElementSelection={setElementSelection}
              currentMode={currentMode}
              unit={prop.unit}
            />
          ))}
      </PropGroup>

      {element.calc && (
        <PropGroup label={t("Calculated")}>
          {element.calc.map((prop, index) => (
            <PropField
              setCurrentMode={setCurrentMode}
              disabled={disabled}
              value={prop.value}
              label={prop.label}
              type={prop.type}
              propKey={prop.key}
              onChange={(value, key) => handlePropChange(value, key, "calc")}
              key={prop.key}
              errors={errors}
              elementSelection={elementSelection}
              setElementSelection={setElementSelection}
              currentMode={currentMode}
              unit={prop.unit}
            />
          ))}
        </PropGroup>
      )}
    </Box>
  );
};
