import { Box, Paper, Typography, useTheme } from "@mui/material";
import { createElement } from "react";
import BugReportIcon from "@mui/icons-material/BugReport";
import { tokens } from "../../../theme/theme";

export const TypeCards = ({
  field,
  dataCollection,
  errors = {},
  updateValidationOnChange,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  function handleChange(value) {
    updateValidationOnChange([field.key], value);
  }

  return (
    <Box>
      <Typography>{field.label}</Typography>
      <Box
        className="flex flex-row gap-5 overflow-x-auto p-3"
        sx={{
          justifyContent: field.alignment ? field.alignment : "center",
        }}
      >
        {field.options.map((item, index) => (
          <Paper
            key={index}
            elevation={
              Boolean(dataCollection[field.key] === item.value) ? 3 : 0
            }
            className="p-2 w-full cursor-pointer transition-colors"
            sx={{
              borderColor: Boolean(dataCollection[field.key] === item.value)
                ? colors.secondary[300]
                : colors.contrast[100],
              bgcolor: colors.contrast[100],
              borderWidth: "2px",
            }}
            onClick={() => handleChange(item.value)}
          >
            {createElement(item.component ? item.component : DefaultCard, {
              item,
              selected: Boolean(dataCollection[field.key] === item.value),
            })}
          </Paper>
        ))}
      </Box>
    </Box>
  );
};

export const DefaultCard = ({ item, selected }) => {
  const Icon = item.icon ? item.icon : BugReportIcon;
  return (
    <Box className="flex flex-col gap-2 w-full justify-center items-center py-4">
      <Icon sx={{ fontSize: "100px" }} />
      <Typography variant="h4">{item.label}</Typography>
    </Box>
  );
};
