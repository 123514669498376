import { Box, Chip, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme/theme";
import { SidebarMenuItemGroup } from "./sidebar/SidebarMenuItemGroup";
import sidebarMenuItemList from "./sidebar/sidebarMenuItemList";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";

export const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [open, setOpen] = useState(false);

  const openWidth = "300px";
  const closedWidth = "0px";

  return (
    <Box className=" flex h-full z-10 relative">
      <IconButton
        onClick={() => setOpen(!open)}
        variant="contained"
        sx={{ position: "absolute", right: "-50px" }}
        className="top-4"
      >
        <MenuIcon />
      </IconButton>
      <Box
        className=" overflow-hidden h-full flex flex-col "
        sx={{
          width: open ? openWidth : closedWidth,
          bgcolor: colors.contrast[100],
          boxShadow: ` 0 0 10px ${colors.contrast[300]}`,
          transition: "width 0.3s",
        }}
      >
        <Box
          className=" overflow-hidden h-full flex flex-col "
          sx={{
            width: openWidth,
          }}
        >
          <Box className="p-2 w-full h-full flex flex-col ">
            <Box className="flex flex-row justify-between items-center">
              <Box className="flex flex-row gap-2 justify-center items-center">
                <img className="w-16" src="/assets/svg/logo.svg" />
                <Typography variant="h4">Ziggurat</Typography>
              </Box>
              <Chip label="free" />
            </Box>
            {sidebarMenuItemList.map((itemGroup, index) => (
              <SidebarMenuItemGroup group={itemGroup} open={open} key={index} />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
