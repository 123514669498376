import { Fragment, useEffect, useState } from "react";
import { useLocation, Navigate } from "react-router-dom";
import Layout from "../layout/Layout";
import { initKeycloak } from "./keycloak";
import AuthScreen from "./AuthScreen";

const RequireAuth = ({ debug, children }) => {
  const location = useLocation();
  const [authorized, setAuthorized] = useState(false);
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("jwtToken") || debug) setAuthorized(true);
    initKeycloak(handleAuthResponse, (message) => console.error(message));
  }, []);

  function handleAuthResponse(auth) {
    setAuthorized(auth);
    setIsChecking(false);
  }

  return isChecking ? (
    <AuthScreen />
  ) : (
    <Fragment>
      {authorized ? (
        <Layout>{children}</Layout>
      ) : (
        <Navigate to="/login" state={{ from: location }} replace />
      )}
    </Fragment>
  );
};

export default RequireAuth;
