import { useEffect, useRef, useState } from "react";
import { Polyline, Tooltip } from "react-leaflet";
import useMapElementStyles from "../useMapElementStyles";
import {
  findIndexById,
  findMapElementById,
} from "../helperFunctions/findMapElementById";
import { calcEdgeWeight } from "./calcEdgeWeight";
import "leaflet-arrowheads";
import { calculateDistance } from "../helperFunctions/findMaxDistance";

const Edge = ({
  element,
  currentMode,
  onSelect,
  selectedElement,
  mapElements,
  setMapElements,
  weightSettings,
  index,
}) => {
  const mapStyles = useMapElementStyles();

  const [key, setKey] = useState(0);
  const [positions, setPositions] = useState(null);
  const edgeRef = useRef(null);

  const vertexIndex = {
    start: findIndexById(mapElements, element.startNodeId),
    end: findIndexById(mapElements, element.endNodeId),
  };
  const isSaved = element.isSaved;
  const isSelected = selectedElement.id === element.id;

  useEffect(() => {
    updatePositions();
    checkLength();
  }, [
    mapElements[vertexIndex.start].position,
    mapElements[vertexIndex.end].position,
  ]);

  useEffect(() => {
    setKey(key + 1);
  }, [isSelected, currentMode, isSaved]);

  // weight
  const stylesWeight = mapStyles.edge.size[currentMode]
    ? mapStyles.edge.size[currentMode]
    : mapStyles.edge.size.default;

  const diameter =
    (element.calc && element.calc.find((obj) => obj.label === "diameter")) ||
    null;

  const weight =
    currentMode === "view" && diameter
      ? calcEdgeWeight(diameter.value, weightSettings) || stylesWeight
      : stylesWeight;

  var color = isSelected
    ? mapStyles.edge.colors.selected
    : mapStyles.edge.colors.default;
  if (!isSaved) color = mapStyles.node.colors.notSaved;

  function updatePositions() {
    const startNode = mapElements[vertexIndex.start];
    const endNode = mapElements[vertexIndex.end];
    setPositions([startNode.position, endNode.position]);
  }

  function checkLength() {
    const startNode = mapElements[vertexIndex.start];
    const endNode = mapElements[vertexIndex.end];
    const newLength =
      calculateDistance(startNode.position, endNode.position) * 1000;
    const lengthProp = element.props.find((obj) => obj.label === "length");

    if (
      !lengthProp ||
      Math.round(lengthProp.value * 100) !== Math.round(newLength * 100)
    )
      updateLength(newLength);
  }

  function updateLength(newLength) {
    const newProps = [
      {
        value: newLength,
        key: "length",
        label: "length",
        type: "display",
        unit: "m",
      },
    ];
    element.props.forEach((prop) => {
      if (prop.label !== "length") return newProps.push(prop);
    });
    const newElement = { ...element, props: newProps, isSaved: false };
    var newMapElements = mapElements;
    newMapElements[index] = newElement;
    setMapElements(newMapElements);
  }

  const handleMouseOver = () => {
    if (edgeRef.current) {
      edgeRef.current.setStyle({ color: mapStyles.edge.colors.hover }); // Change color on hover
    }
  };

  const handleMouseOut = () => {
    if (edgeRef.current) {
      edgeRef.current.setStyle({ color: color }); // Reset color on mouseout
    }
  };

  const eventHandlers = {
    click: () => onSelect(element),
    mouseover: handleMouseOver,
    mouseout: handleMouseOut,
  };

  // custom edge
  /*
  const arrowheads = {
    fill: true,
    color: "transparent",
    fillColor: mapStyles.edge.colors.arrow || color,
    yawn: 40,
    size: "20px",
    frequency: "40m",
    offsets: { start: "0px", end: "30px" },
  };

  useEffect(() => {
    if (!edgeRef || !edgeRef.current) return;
    const polyline = edgeRef.current;
    if (arrowheads) {
      polyline.arrowheads(arrowheads);
      polyline._update();
    }

    return () => {
      if (arrowheads) {
        polyline.deleteArrowheads();
        polyline.remove();
      }
    };
  }, [edgeRef]);
  */

  if (!positions) return;
  return (
    <Polyline
      key={key}
      className={currentMode === "view" && "edge-animated"}
      ref={edgeRef}
      weight={weight}
      eventHandlers={eventHandlers}
      color={color}
      positions={positions}
    >
      {element.name && (
        <Tooltip direction="bottom" offset={[0, weight / 2]} opacity={1}>
          {element.name}
        </Tooltip>
      )}
    </Polyline>
  );
};

export default Edge;
