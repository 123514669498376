import { createElement, useEffect, useState } from "react";
import TypeDate from "./form-fields/TypeDate";
import TypeText from "./form-fields/TypeText";
import TypeColor from "./form-fields/TypeColor";
import * as Yup from "yup";
import TypeHidden from "./form-fields/TypeHidden";
import TypeDateRange from "./form-fields/TypeDateRange";
import TypeDateTimeRange from "./form-fields/TypeDateTimeRange";
import TypeCustomSelect from "./form-fields/TypeCustomSelect";
import TypeDivider from "./form-fields/TypeDivider";
import TypeCheckbox from "./form-fields/TypeCheckbox";
import { TypeCards } from "./form-fields/TypeCards";
import { TypeCoordinates } from "./form-fields/TypeCoordinates";
import TypeName from "./form-fields/TypeName";
import { TypeLabel } from "./form-fields/TypeLabel";
import TypeSmallCheckbox from "./form-fields/TypeSmallCheckbox";
import TypeNumber from "./form-fields/TypeNumber";
import TypeCustomMultiSelect from "./form-fields/TypeCustomMultiSelect";
import TypeCheckNFields from "./form-fields/TypeCheckNFields";
import TypeSelectNFields from "./form-fields/TypeSelectNFields";
import TypeCostFunction from "./form-fields/TypeCostFunction";
import TypeDiameterTable from "./form-fields/TypeDiameterTable";

const demoSchema = Yup.object().shape({
  date: Yup.string().required("Date is required"),
});

export const typeMap = {
  date: TypeDate,
  text: TypeText,
  textBig: TypeName,
  color: TypeColor,
  checkbox: TypeCheckbox,
  hidden: TypeHidden,
  divider: TypeDivider,
  dateRange: TypeDateRange,
  dateTimeRange: TypeDateTimeRange,
  select: TypeCustomSelect,
  multiSelect: TypeCustomMultiSelect,
  cards: TypeCards,
  coordinates: TypeCoordinates,
  label: TypeLabel,
  smallCheckbox: TypeSmallCheckbox,
  number: TypeNumber,
  checkNFields: TypeCheckNFields,
  selectNFields: TypeSelectNFields,
  costFunction: TypeCostFunction,
  diameterTable: TypeDiameterTable,
};

const FormFields = ({
  fields,
  updateDataCollection,
  dataCollection,
  validationSchema = Yup.object().shape(),
  startDataCollection,
}) => {
  const [fieldElements, setFieldElements] = useState([]);

  useEffect(() => {
    if (!fields) {
      const newFields = [];
      const keys = Object.keys(typeMap);
      keys.forEach((key) => {
        newFields.push({
          label: key,
          key: key,
          type: key,
          options: [
            { label: "text", value: "field" },
            { label: "date", value: "date" },
          ],
          field: {
            label: "TEST",
            key: "bla",
            type: "textBig",
          },
          date: {
            label: "TEST",
            key: "date",
            type: "date",
          },
        });
      });
      setFieldElements(newFields);
    } else {
      setFieldElements(fields);
    }
  }, [fields]);

  const [errors, setErrors] = useState({});

  async function updateValidationOnChange(key, fieldValue) {
    validateField(key, fieldValue);
    updateDataCollection({ [key]: fieldValue });
  }

  // validate every field onChange!
  const validateField = async (fieldKey, fieldValue) => {
    try {
      const fieldSchema = Yup.object().shape({
        [fieldKey]: validationSchema.fields[fieldKey],
      });
      await fieldSchema.validate({ [fieldKey]: fieldValue });
      // Validation passed for the specific field

      // clear the error
      const newErrors = { ...errors };
      delete newErrors[fieldKey];
      setErrors(newErrors);
    } catch (err) {
      // Validation failed for the specific field
      if (err instanceof Yup.ValidationError) {
        setErrors({ ...errors, [fieldKey]: err.message });
      }
    }
  };

  return (
    <>
      {fieldElements.map((field, index) => {
        if (!typeMap[field.type])
          return console.error(`INVALIDE Form Field Type: ${field.type}`);
        return createElement(typeMap[field.type], {
          dataCollection,
          startDataCollection,
          field,
          errors,
          updateValidationOnChange,
          key: index,
        });
      })}
    </>
  );
};

export default FormFields;
