import EntryEditable from "./EntryEditable";
import EntryConst from "./EntryConst";

const CustomPosition = ({
  col,
  items,
  index,
  onChange,
  setItems,
  onFocus = () => {},
  descriptionVars,
  showVars,
}) => {
  return col.editable ? (
    <EntryEditable
      col={col}
      items={items}
      index={index}
      onChange={onChange}
      onFocus={onFocus}
    />
  ) : (
    <EntryConst
      unit={col.custom ? "" : col.unit}
      value={
        col.custom ? col.custom(items[index], col.unit) : items[index][col.key]
      }
      className={col.className}
    />
  );
};

export default CustomPosition;
