export const calculateCenter = (polygons) => {
  let totalLat = 0;
  let totalLng = 0;
  let totalPositions = 0;

  // default Center
  if (!polygons) return { lat: 51.376429, lng: 10.312369 };

  polygons.forEach((polygon) => {
    polygon.positions.forEach((position) => {
      totalLat += position[0];
      totalLng += position[1];
      totalPositions++;
    });
  });

  const centerLat = totalLat / totalPositions;
  const centerLng = totalLng / totalPositions;

  return { lat: centerLat, lng: centerLng };
};
