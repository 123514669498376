import { createElement, useEffect, useState } from "react";
import { NodeProperties } from "./node/NodeProperties";
import { Box, Button, useTheme } from "@mui/material";
import useMapElementStyles from "./useMapElementStyles";
import { updateMapElementById } from "./helperFunctions/updateMapElementById";
import { EdgeProperties } from "./edge/EdgeProperties";
import { AreaProperties } from "./area/AreaProperties";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import { useTranslation } from "react-i18next";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";

import { tokens } from "../../theme/theme";

export const MapElementPropertyManager = ({
  selectedElement,
  mapElements,
  setMapElements,
  setSelectedElement,
  disabled,
  show,
  elementSelection,
  setCurrentMode,
  setElementSelection,
  currentMode,
}) => {
  const { t } = useTranslation();
  const styles = useMapElementStyles();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const [element, setElement] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (selectedElement.type) {
      setElement(selectedElement);
      validate(selectedElement);
    }
  }, [selectedElement]);
  const elementTypes = {
    node: NodeProperties,
    edge: EdgeProperties,
    area: AreaProperties,
  };

  function handleClose() {
    setSelectedElement({});
  }

  function handleUpdate(value, propKey) {
    if (validate({ ...element, [propKey]: value })) {
      setMapElements(
        updateMapElementById(mapElements, { ...element, [propKey]: value })
      );
    } else {
      setElement({ ...element, [propKey]: value });
    }
  }

  const [errors, setErrors] = useState(null);
  function validate(updatedElement) {
    var newErrors = {};
    // check name is unique
    mapElements.forEach((element) => {
      if (
        element.id !== updatedElement.id &&
        element.name === updatedElement.name
      ) {
        newErrors.name = t("Name has to be unique!");
      }
    });

    setErrors(newErrors);

    const numbOfErrors = Object.keys(newErrors);
    return numbOfErrors.length === 0;
  }

  if (!show) return;
  return (
    <Box
      sx={{ width: open ? "450px" : "0px", transition: "0.3s" }}
      className="relative"
    >
      <Box
        className="border-r-2 h-full relative overflow-y-auto"
        sx={{
          borderColor: styles.selected,
        }}
      >
        {element.type ? (
          createElement(elementTypes[element.type], {
            element,
            onClose: () => setSelectedElement({}),
            onUpdate: handleUpdate,
            disabled,
            errors,
            setCurrentMode,
            setElementSelection,
            elementSelection,
            currentMode,
            mapElements,
          })
        ) : (
          <Empty />
        )}
      </Box>
      <Button
        sx={{
          position: "absolute",
          zIndex: 999,
          bgcolor: colors.contrast[100],
          padding: 0,
          minWidth: 0,
          borderRadius: "0 10px 10px 0",
        }}
        variant="contained"
        className=" top-1/2 -translate-y-1/2 right-0 translate-x-full h-10 w-10"
        onClick={() => setOpen(!open)}
      >
        <TroubleshootIcon sx={{ color: colors.primary[400] }} />
      </Button>
    </Box>
  );
};

const Empty = () => {
  const { t } = useTranslation();
  return (
    <Box className=" h-full flex flex-col justify-center items-center">
      <AdsClickIcon />
      {t("Select a Element first")}
    </Box>
  );
};
