import { useTheme } from "@mui/material";
import { tokens } from "../../theme/theme";

const useMapElementStyles = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const selected = "orange";
  const hover = colors.blueAccent[300];

  return {
    selected: selected,
    node: {
      colors: {
        default: colors.blueAccent[400],
        selected: selected,
        preview: colors.blueAccent[400],
        edit: colors.blueAccent[500],
        hover: hover,
        isOutlet: colors.redAccent[400],
        notSaved: "#828282",
        inSelection: "#a87b00",
      },
      size: {
        default: 5,
        draggable: 7,
        preview: 4,
        edgeTool: 6,
        nodeTool: 4,
        areaTool: 1,
        delete: 7,
      },
      fillOpacity: {
        default: 1,
        draggable: 0.2,
      },
    },
    edge: {
      colors: {
        default: colors.blueAccent[500],
        selected: selected,
        preview: "orange",
        hover: hover,
        notSaved: "#828282",
        arrow: `${colors.blueAccent[200]}E3`,
      },
      size: {
        default: 4,
        preview: 3,
        edgeTool: 2,
        nodeTool: 2,
        areaTool: 1,
        delete: 5,
      },
    },

    projectAreaNode: {
      colors: {
        default: "rgba(0,0,0, 0.5)",
        selected: selected,
        preview: colors.redAccent[600],
        projectAreaTool: colors.redAccent[600],
        hover: hover,
      },
      size: {
        default: 0.1,
        projectAreaTool: 4,
        draggable: 5,
        delete: 4,
      },
    },
    projectArea: {
      dashArray: "5, 10",
      fillOpacity: {
        default: 0.5,
        preview: 0.5,
        projectAreaTool: 0.3,
      },
      colors: {
        default: "rgba(0,0,0, 0.5)",
        selected: selected,
        preview: "orange",
        hover: hover,
      },
      bgColors: {
        default: "rgba(0,0,0)",
        selected: selected,
        preview: "orange",
        hover: hover,
      },
      size: {
        default: 2,
        projectAreaTool: 3,
        preview: 2,
        delete: 3,
      },
    },
    areaNode: {
      colors: {
        default: colors.blueAccent[500],
        selected: selected,
        preview: colors.blueAccent[500],
        edit: colors.blueAccent[500],
        hover: hover,
        areaTool: "orange",
        notSaved: "#828282",
      },
      size: {
        default: 0.1,
        areaTool: 4,
        draggable: 5,
        delete: 4,
      },
    },
    area: {
      dashArray: "5, 10",
      fillOpacity: {
        default: 0.2,
        preview: 0.3,
        areaTool: 0.2,
      },
      colors: {
        default: colors.greenAccent[500],
        selected: selected,
        preview: "orange",
        hover: hover,
        notSaved: "#828282",
      },
      size: {
        default: 2,
        areaTool: 1,
        preview: 1,
        delete: 1,
      },
    },
    tools: {
      default: {
        normal: colors.greenAccent[500],
        hover: hover,
        active: "orange",
        text: "white",
      },
      warning: {
        normal: colors.redAccent[400],
        hover: hover,
        active: "orange",
        text: "white",
      },
    },
    actions: {
      normal: colors.greenAccent[500],
      success: colors.greenAccent[500],
      hover: hover,
      warning: "orange",
      error: colors.redAccent[400],
    },
  };
};

export default useMapElementStyles;
