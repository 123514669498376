import { InputAdornment, TextField } from "@mui/material";

export const PropTypeNumber = ({
  label,
  value,
  onChange,
  propKey,
  disabled,
  errors,
  unit,
}) => {
  function handleChange(e) {
    const newValue = parseInt(e.target.value);
    onChange(newValue || "", propKey);
  }

  return (
    <TextField
      label={label}
      type="text"
      variant="filled"
      value={value}
      onChange={handleChange}
      disabled={disabled}
      error={Boolean(errors[propKey])}
      helperText={errors[propKey]}
      InputProps={{
        endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
      }}
    />
  );
};
