import { PropGroup } from "../PropGroup";
import { PropsPositionField } from "../PropsPositionField";

export const PropTypePositions = ({ value, onChange, propKey, disabled }) => {
  function handleChange() {
    onChange(value, propKey);
  }
  if (!value) return;
  return (
    <PropGroup label="Location" borderWidth={0}>
      {value.map((position, index) => (
        <PropsPositionField
          disabled={disabled}
          label={`Point ${index + 1}`}
          value={position}
          propKey={index}
          onChange={handleChange}
        />
      ))}
    </PropGroup>
  );
};
