import { Area } from "../area/Area";
import Edge from "../edge/Edge";
import Node from "../node/Node";
import { ProjectArea } from "../projectArea/ProjectArea";

const ElementTypes = {
  area: Area,
  projectArea: ProjectArea,
  edge: Edge,
  node: Node,
};

export default ElementTypes;
