import { useTheme } from "@mui/material";
import { useState } from "react";
import CustomPosition from "./entries/CustomPosition";
import { tokens } from "../../../theme/theme";
import { stringToNumber } from "../../numberConverter";

const Entry = ({
  col,
  index,
  setItems,
  items,
  onFocus,
  descriptionVars,
  showVars,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  if (col.hidden) return;

  function handleChange(e) {
    var newItems = items;
    if (col.number) {
      newItems[index][col.key] = stringToNumber(
        e.target.value,
        col.decimalPlaces ? col.decimalPlaces : 2
      );
    } else {
      newItems[index][col.key] = e.target.value;
    }

    setItems([...newItems]);
  }

  return (
    <td
      className={col.className + " align-top backdrop-blur-lg"}
      style={{
        width: col.width ? col.width : "max-content",
        maxWidth: col.width ? col.width : "unset",
        textAlign: col.align ? col.align : "left",
        padding: "5px 5px",
        borderTop: "1px solid " + colors.primary[400],
        borderBottom: "1px solid " + colors.primary[400],
      }}
    >
      <CustomPosition
        onFocus={onFocus}
        col={col}
        items={items}
        index={index}
        onChange={handleChange}
        setItems={setItems}
        descriptionVars={descriptionVars}
        showVars={showVars}
      />
    </td>
  );
};

export default Entry;
