import { Box } from "@mui/material";
import { useEffect } from "react";
import FormFields from "../../form/FormFields";

const StepCustomStartUp = ({
  fields,
  updateDataCollection,
  dataCollection,
  updateValidation,
  validationSchema,
  debug,
}) => {
  // check validation
  useEffect(() => {
    validateDataCollection();
  }, [dataCollection, fields]);

  const validateDataCollection = async () => {
    if (!validationSchema) return updateValidation(true);

    try {
      await validationSchema.validate(dataCollection);
      // validation passed for the specific field
      updateValidation(true);
    } catch (err) {
      // validation failed for the specific field
      if (debug) console.log("NOT VALID", err);
      updateValidation(false);
    }
  };

  return (
    <Box className="flex flex-col gap-4 pt-2">
      <FormFields
        fields={fields}
        updateDataCollection={updateDataCollection}
        dataCollection={dataCollection}
        validationSchema={validationSchema}
        startDataCollection={{}}
      />
    </Box>
  );
};

export default StepCustomStartUp;
